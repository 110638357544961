
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Bar, Doughnut } from "react-chartjs-2";
import Modal from "react-modal";
import {BASE_URL} from '../../constant/constant'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const ShowHwbDashboard = () => {
  const [usersData, setUsers] = useState([]);
  const [totalSL, setTotalSL] = useState(0);
  const [sectionScoutCount, setSectionScoutCount] = useState({
    Scout: 0,
    Cub: 0,
    Rover: 0,
  });
  const [sectionGuideCount, setSectionGuideCount] = useState({
    Guide: 0,
    Bulbul: 0,
    Ranger: 0,
  });
  const [activeParchment, setActiveParchment] = useState(0);
  const [inactiveParchment, setInactiveParchment] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalTitle, setModalTitle] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true); // Start loading
    
    
      const response = await axios.get(`${BASE_URL}/api/v1/hwbuser`,axiosConfig);
      const users = response.data;
      console.log(response.data,"response");

      setUsers(users);

      // Aggregate data for Scout sections
      setSectionScoutCount({
        Scout: users.filter((user) => user.SECTION?.trim().toLowerCase() === "scout")
          .length,
  
        Cub:users.filter(
          (user) => user.SECTION?.trim().toLowerCase() === "cub"
        ).length,
        
        Rover: users.filter((user) => user.SECTION?.trim().toLowerCase() === "rover")
          .length,
      });

      // Aggregate data for Guide sections
      setSectionGuideCount({
        Guide: users.filter((user) => user.SECTION === "Guide")
          .length,
        Bulbul: users.filter((user) => user.SECTION === "Bulbul")
          .length,
        Ranger: users.filter((user) => user.SECTION === "Ranger")
          .length,
      });

      // Updated Parchment Issue Count based on HWB_PARCHMENT_NO
      setActiveParchment(
        users.filter((user) => user.PARCHMENT_NO).length
      );
      setInactiveParchment(
        users.filter((user) => !user.PARCHMENT_NO).length
      );

      // Set the total number of users
      setTotalSL(users.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const chartData = {
    labels: ["Scout", "Cub", "Rover", "Guide", "Bulbul", "Ranger", "Parchment Issued", "Parchment Not Issued"],
    datasets: [
      {
        label: "Scout Section",
        data: [
          sectionScoutCount.Scout,
          sectionScoutCount.Cub,
          sectionScoutCount.Rover,
          0,
          0,
          0,
          0, // Parchment Issued
          0, // Parchment Not Issued
        ],
        backgroundColor: "#4CAF50",
      },
      {
        label: "Guide Section",
        data: [
          0,
          0,
          0,
          sectionGuideCount.Guide,
          sectionGuideCount.Bulbul,
          sectionGuideCount.Ranger,
          0, // Parchment Issued
          0, // Parchment Not Issued
        ],
        backgroundColor: "#FFB74D",
      },
      {
        label: "Parchment Status",
        data: [
          0,
          0,
          0,
          0,
          0,
          0,
          activeParchment,    // Parchment Issued
          inactiveParchment,  // Parchment Not Issued
        ],
        backgroundColor: "#2196F3",
      },
    ],
  };

  const openModal = (title, data) => {
    setModalTitle(title);
    setModalData(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData({});
  };

  const doughnutData = (data) => ({
    labels: Object.keys(data),
    datasets: [
      {
        label: `${modalTitle} Breakdown`,
        data: Object.values(data),
        backgroundColor: ["#4CAF50", "#FFB74D", "#2196F3"],
      },
    ],
  });

  const openParchmentModal = () => {
    const data = {
      "Parchment Issued": activeParchment,
      "Parchment Not Issued": inactiveParchment,
    };
    openModal("Parchment Status", data);
  };

  return (
    <div className="px-6 py-6 bg-gradient-to-r from-blue-200 via-indigo-200 to-purple-200 min-h-screen">
      <div className="flex justify-center items-center mb-6">
        <h1 className="text-3xl font-extrabold text-gray-900 uppercase">
          HWB Dashboard
        </h1>
      </div>

      {loading ? (
        <p className="text-center text-gray-500">Loading data...</p>
      ) : (
        <div className="bg-white rounded-lg p-6 shadow-xl text-gray-700">
          <div className="grid grid-cols-2 gap-4">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/hwblist");
              }}
            >
              <div className="bg-blue-100 p-4 rounded-lg shadow-md">
                <p className="font-bold text-xl text-gray-700">Total HWB Holder</p>
                <p className="text-3xl font-extrabold text-blue-600">{totalSL}</p>
              </div>
            </div>
            <div className="bg-red-100 p-4 rounded-lg shadow-md" onClick={openParchmentModal}>
              <p className="font-bold text-xl text-gray-700">Parchment Status</p>
              <p>Parchment Issued: <span className="font-bold text-green-600">{activeParchment}</span></p>
              <p>Parchment Not Issued: <span className="font-bold text-red-600">{inactiveParchment}</span></p>
            </div>

            <div
              className="bg-green-100 p-4 rounded-lg shadow-md cursor-pointer"
              onClick={() => openModal("Scout Section", sectionScoutCount)}
            >
              <p className="font-bold text-xl text-black">Scout Section</p>
              <p className="text-xl text-black font-regular mt-2">
                Total Scout Section:{" "}
                <span className="font-bold text-green-600">
                  {sectionScoutCount.Scout + sectionScoutCount.Cub + sectionScoutCount.Rover}
                </span>
              </p>
              <p>Total 'Scout': <span className="font-bold text-blue-600">{sectionScoutCount.Scout}</span></p>
              <p>Total 'Cub': <span className="font-bold text-blue-600">{sectionScoutCount.Cub}</span></p>
              <p>Total 'Rover': <span className="font-bold text-blue-600">{sectionScoutCount.Rover}</span></p>
            </div>

            <div
              className="bg-purple-100 p-4 rounded-lg shadow-md cursor-pointer"
              onClick={() => openModal("Guide Section", sectionGuideCount)}
            >
              <p className="font-bold text-xl text-gray-700">Guide Section</p>
              <p className="text-xl text-gray-700 mt-2">
                Total Guide Section:{" "}
                <span className="font-bold text-purple-600">
                  {sectionGuideCount.Guide + sectionGuideCount.Bulbul + sectionGuideCount.Ranger}
                </span>
              </p>
              <p>Total 'Guide': <span className="font-bold text-blue-600">{sectionGuideCount.Guide}</span></p>
              <p>Total 'Bulbul': <span className="font-bold text-blue-600">{sectionGuideCount.Bulbul}</span></p>
              <p>Total 'Ranger': <span className="font-bold text-blue-600">{sectionGuideCount.Ranger}</span></p>
            </div>
          </div>
        </div>
      )}

      <div className="mt-10">
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: { display: true, position: "top" },
              title: {
                display: true,
                text: "Scout & Guide Section Status Overview",
                font: {
                  size: 20,
                  weight: "bold",
                },
                color: "black",
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                max: Math.max(
                  ...Object.values(sectionScoutCount),
                  ...Object.values(sectionGuideCount),
                  activeParchment,
                  inactiveParchment
                ) + 5,
              },
            },
          }}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Section Breakdown"
        className="w-1/4 py-32 mx-auto"
      >
        <h2 className="text-2xl font-bold mb-4 flex justify-center">{modalTitle}</h2>
        <Doughnut data={doughnutData(modalData)} />
        <button
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
          onClick={closeModal}
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

export default ShowHwbDashboard;
