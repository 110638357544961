

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../constant/constant";

const AllBasicData = () => {
  const [basic, setBasic] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [wing, setWing] = useState("");
  const [subwing, setSubwing] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [venue, setVenue] = useState("");
  const [leader, setLeader] = useState("");
  const [certificateNumber, setCertificateNumber] = useState("");
  const [certificateDate, setCertificateDate] = useState("");

  useEffect(() => {
    getBasicData();
  }, []);

  const getBasicData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/alladvancedDetails`
      );
      setBasic(response.data);
      setFilteredData(response.data);
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  const handleSearch = () => {
    const filtered = basic.filter((item) => {
      return (
        (wing ? item.wing.toLowerCase() === wing.toLowerCase() : true) &&
        (subwing
          ? item.subwing.toLowerCase() === subwing.toLowerCase()
          : true) &&
        (fromDate ? new Date(item.fromDate) >= new Date(fromDate) : true) &&
        (toDate ? new Date(item.toDate) <= new Date(toDate) : true) &&
        (venue
          ? item.venue.toLowerCase().includes(venue.toLowerCase())
          : true) &&
        (leader
          ? item.leader.toLowerCase().includes(leader.toLowerCase())
          : true) &&
        (certificateNumber
          ? item.certificateNumber
              .toLowerCase()
              .includes(certificateNumber.toLowerCase())
          : true) &&
        (certificateDate
          ? new Date(item.certificateDate).toISOString().split("T")[0] ===
            certificateDate
          : true)
      );
    });
    setFilteredData(filtered);
  };

  const getSubwingOptions = () => {
    if (wing === "Guide") {
      return ["Bulbul", "Guide", "Ranger"];
    } else if (wing === "Scout") {
      return ["Cub", "Scout", "Rover"];
    } else {
      return [];
    }
  };

  return (
    <>
      <ToastContainer />
      <h3 className="font-bold text-xl pl-5 uppercase">ALL Advance Details</h3>
      <div className="flex flex-row gap-1 my-5">
        <select
          value={wing}
          onChange={(e) => setWing(e.target.value)}
          className="px-1 py-1 w-24 text-xs font-semibold border border-gray-300 rounded-md"
        >
          <option value="">Select Wing</option>
          <option value="Guide">Guide</option>
          <option value="Scout">Scout</option>
        </select>

        <select
          value={subwing}
          onChange={(e) => setSubwing(e.target.value)}
          className="px-1 py-1 w-28 text-xs font-semibold border border-gray-300 rounded-md"
        >
          <option value="">Select Subwing</option>
          {getSubwingOptions().map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className="px-1 py-1 w-28 text-xs font-semibold border border-gray-300 rounded-md"
        />
        <input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          className="px-1 py-1 w-28 text-xs font-semibold  border border-gray-300 rounded-md"
        />
        <input
          type="text"
          placeholder="Enter the Venue"
          value={venue}
          onChange={(e) => setVenue(e.target.value)}
          className="px-1 py-1  w-24  text-xs font-semibold border border-gray-300 rounded-md"
        />
        <input
          type="text"
          placeholder="Enter the Leader"
          value={leader}
          onChange={(e) => setLeader(e.target.value)}
          className="px-1 py-1 w-24 text-xs font-semibold border border-gray-300 rounded-md"
        />
        <input
          type="text"
          placeholder="Certificate Number"
          value={certificateNumber}
          onChange={(e) => setCertificateNumber(e.target.value)}
          className="px-1 py-1 w-24 text-xs font-semibold border border-gray-300 rounded-md"
        />
        <input
          type="date"
          value={certificateDate}
          onChange={(e) => setCertificateDate(e.target.value)}
          className="px-1 py-1 w-28 text-xs font-semibold border border-gray-300 rounded-md"
        />
        <button
          onClick={handleSearch}
          className="px-6 py-1 bg-blue-500 text-white rounded-md"
        >
          Search
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Wing
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Subwing
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                From Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                To Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Venue
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Leader
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Certificate Number
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Certificate Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.wing}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.subwing}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.fromDate}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.toDate}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.venue}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.leader}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.certificateNumber}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.certificateDate}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AllBasicData;
