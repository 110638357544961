// import React, { useState } from "react";
// import LogoImg from "../img/big logo (1).png";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import image from "../img/image.jpeg";
// import { BASE_URL } from "../constant/constant";
// import { ThreeDots } from "react-loader-spinner";
// import imagebg from "../../src/img/login.png";
// import { FaEye, FaEyeSlash } from "react-icons/fa6";
// const Login = () => {
//   const [password, setPassword] = useState("");
//   const [username, setUsername] = useState("");
//   const [loading, setLoading] = useState("");
//   const [showpassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const data = { email: username, password };

//     setLoading(true);

//     try {
//       // Sending the request
//       const response = await axios.post(`${BASE_URL}/api/admin/login`, data, {
//         headers: {
//           "Content-Type": "application/json", // Simple headers to prevent preflight
//         },
//       });

//       const result = response.data;
//       console.log(result);

//       const { token, user } = response.data;
//       const { role, _id: id, isDisabled } = user;

//       if (isDisabled) {
//         toast.error("Account is disabled. Please contact support.");
//         setLoading(false);
//         return;
//       }

//       // Save to localStorage and navigate
//       localStorage.setItem("adminToken", token);
//       localStorage.setItem("id", id);
//       localStorage.setItem("role", role);

//       toast.success("Login Successfully");
//       setTimeout(() => {
//         setLoading(false);
//         navigate("/Dashboard");
//       }, 2000);
//     } catch (error) {
//       console.error("Error:", error);

//       const errorMessage =
//         error.response?.data?.message || "An unexpected error occurred.";
//       toast.error(errorMessage);
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <div
//         className="h-screen w-screen flex items-center justify-center bg-cover bg-center relative "
//         style={{ backgroundImage: `url(${imagebg})` }}
//       >
//         <div className="absolute inset-x-0  inset-y-0 top-20 right-20 bottom-20 left-20 overflow-hidden">
//           <div className="grid grid-cols-1 md:grid-cols-2 ">
//             {/* Left Section */}
//             <div className="relative flex flex-col items-center justify-center ">
//               {/* Logo */}
//               <img
//                 src={LogoImg}
//                 alt="Logo"
//                 className="absolute top-5 md:top-14 w-48 md:w-96 h-12 "
//               />

//               {/* Background Image */}
//               <img
//                 src={image}
//                 alt="Background"
//                 className="hidden lg:block h-[505px] max-w-full w-full rounded-l-md"
//               />
//             </div>

//             {/* Right Section */}
//             <div className="p-14  bg-[#0e0905] rounded-r-md ">
//               <ToastContainer />
//               <div className=" p-5 rounded shadow-lg max-w-md w-full bg-[#121212]   ">
//                 <h2 className="text-xl lg:text-3xl font-bold text-center text-white mb-5">
//                   Admin Login Trainer's <br /> Portal
//                 </h2>

//                 <blockquote className="text-center text-gray-300 italic mb-6">
//                   "The influence of a good trainer can never be erased."
//                 </blockquote>

//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-4">
//                     <input
//                       type="email"
//                       placeholder="Email"
//                       className="bg-gray-200 text-black py-2 px-4 w-full rounded outline-none"
//                       value={username}
//                       onChange={(e) => setUsername(e.target.value)}
//                       required
//                     />
//                   </div>
//                   <div className="mb-6">
//                     <input
//                       type={showpassword ? "text" : "password"}
//                       placeholder="Password"
//                       className="bg-gray-200 text-black py-2 px-4 w-full rounded outline-none"
//                       value={password}
//                       onChange={(e) => setPassword(e.target.value)}
//                       required
//                     />

//                     <div
//                       className="absolute inset-y-0 right-24 lg:top-20 top-44 flex items-center cursor-pointer"
//                       onClick={() => setShowPassword(!showpassword)}
//                     >
//                       {showpassword ? (
//                         <FaEye className="text-gray-500 " />
//                       ) : (
//                         <FaEyeSlash className="text-gray-500" />
//                       )}
//                     </div>
//                   </div>

//                   <button
//                     type="submit"
//                     className="w-full bg-[#e8f407] text-black font-semibold py-2 px-4 rounded hover:bg-yellow-400 focus:outline-none"
//                   >
//                     Login
//                   </button>
//                   {loading && (
//                     <div className="flex justify-center items-center mt-4">
//                       <ThreeDots
//                         height="50"
//                         width="50"
//                         color="white" // Blue color for the loader
//                         ariaLabel="loading-indicator"
//                       />
//                     </div>
//                   )}
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Login;



import React, { useState } from "react";
import LogoImg from "../img/big logo (1).png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image from "../img/image.jpeg";
import { BASE_URL } from "../constant/constant";
import { ThreeDots } from "react-loader-spinner";
import imagebg from "../../src/img/login.png";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const Login = () => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [showpassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { email: username, password };

    setLoading(true);

    try {
      const response = await axios.post(`${BASE_URL}/api/admin/login`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const { token, user } = response.data;
      const { role, _id: id, isDisabled } = user;

      if (isDisabled) {
        toast.error("Account is disabled. Please contact support.");
        setLoading(false);
        return;
      }

      localStorage.setItem("adminToken", token);
      localStorage.setItem("id", id);
      localStorage.setItem("role", role);

      toast.success("Login Successfully");
      setTimeout(() => {
        setLoading(false);
        navigate("/Dashboard");
      }, 2000);
    } catch (error) {
      console.error("Error:", error);

      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred.";
      toast.error(errorMessage);
      setLoading(false);
    }
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center relative"
      style={{ backgroundImage: `url(${imagebg})` }}
    >
      <div className="flex flex-col md:flex-row w-full max-w-4xl bg-white rounded-lg shadow-lg overflow-hidden mx-4 sm:mx-6 md:mx-10 h-auto md:h-screen max-h-[500px]">
      {/* <div className="flex flex-col md:flex-row w-full max-w-4xl bg-white rounded-lg shadow-lg overflow-hidden mx-10  h-screen max-h-[500px]  "> */}
        {/* Left Section */}
        <div className="hidden md:flex md:w-1/2 bg-gray-100 items-center justify-center relative">
          <img
            src={LogoImg}
            alt="Logo"
            className="absolute top-5 w-40 md:w-48"
          />
          <img
            src={image}
            alt="Background"
            className="h-full w-full object-cover"
          />
        </div>

        {/* Right Section */}
        <div className="w-full md:w-1/2 flex flex-col justify-center p-6 md:p-10 bg-gradient-to-br from-gray-900 to-gray-800">
          <ToastContainer />
          <div className="text-center">
            <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">
              Admin Login Portal
            </h2>
            <p className="text-gray-300 italic mb-6">
              "The influence of a good trainer can never be erased."
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <input
                type="email"
                placeholder="Email"
                className="bg-gray-200 text-black py-2 px-4   w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="relative">
              <input
                type={showpassword ? "text" : "password"}
                placeholder="Password"
                className="bg-gray-200 text-black py-2 px-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div
                className="absolute inset-y-0 right-4 flex items-center cursor-pointer"
                onClick={() => setShowPassword(!showpassword)}
              >
                {showpassword ? (
                  <FaEye className="text-gray-500" />
                ) : (
                  <FaEyeSlash className="text-gray-500" />
                )}
              </div>
            </div>

            {loading ?(
            <div className="flex justify-center items-center mt-6">
              <ThreeDots
                height="50"
                width="50"
                color="yellow"
                ariaLabel="loading-indicator"
              />
            </div>
          ):
            <button
              type="submit"
              className="w-full bg-yellow-400 text-black font-semibold py-2 px-4 rounded-lg hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            >
              Login
            </button>
}
          </form>

        </div>
      </div>
    </div>
  );
};

export default Login;
