import React from 'react';

const ErrorPage = () => {

  return (
    <section 
>
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-blue-600 dark:text-yellow-400">404</h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-blue-800 md:text-4xl dark:text-yellow-300">Something's missing.</p>
          <p className={`mb-4 text-lg font-light text-gray-600 dark:text-gray-400 `}>Sorry, we can't find that page. You'll find lots to explore on the home page.</p>
          <a
            href="/"
            className="inline-flex text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-yellow-900 my-4"
          >
            Back to Homepage
          </a>
        </div>
      </div>
    </section>
  );
}

export default ErrorPage;
