import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-modal";
import * as XLSX from "xlsx";
import { BASE_URL } from "../../constant/constant";

Modal.setAppElement("#root");
const SearchComponent = () => {
  const [states, setStates] = useState([]);
  const [sections, setSections] = useState([]);
  const [startDob, setStartDob] = useState("");
  const [endDob, setEndDob] = useState("");
  const [startissuedate, setStartIssueDate] = useState("");
  const [endissuedate, setEndIssueDate] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [uid, setUid] = useState("");
  const [email, setEmail] = useState("");
  const [educationQualification, setEducationQualification] = useState([]);
  const [selectedEducationQualification, setSelectedEducationQualification] =
    useState("");
  const [startparchmentNumber, setStartparchmentNumber] = useState("");
  const [endparchmentNumber, setEndparchmentNumber] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [noDataFound, setNoDataFound] = useState(false); 
  const itemsPerPage = 100;

  const [selectedUser, setSelectedUser] = useState(null); // State to hold the selected user for modal
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
    const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  // Function to export data to Excel
  const exportToExcel = () => {
    // Map filtered users to include only the desired fields from altDetails
    const altDetailsData = filteredUsers.map((user) => {
      const { __v, createdAt, updatedAt, _id, ...details } = user.altDetails; // Destructure and exclude unwanted fields
      return details; // Return the remaining fields
    });

    const worksheet = XLSX.utils.json_to_sheet(altDetailsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = "filtered_data.xlsx";
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const clearAll = () => {
    setSelectedStates([]); // Reset selected states
    setStartDob(""); // Reset start DOB
    setEndDob(""); // Reset end DOB
    setStartIssueDate(""); // Reset start issue date
    setEndIssueDate(""); // Reset end issue date
    setUid(""); // Reset UID
    setEmail(""); // Reset email
    setSelectedEducationQualification(""); // Reset selected education qualification
    setStartparchmentNumber(""); // Reset start parchment number
    setEndparchmentNumber(""); // Reset end parchment number
    setSelectedSection(""); // Reset selected section
    setCurrentPage(1); // Reset to the first page
    setFilteredUsers(users); // Reset filtered users to all users
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/user`,axiosConfig);
      const usersData = response.data;
   
      console.log(usersData,"usersData");
     
      if (Array.isArray(usersData) && usersData.length > 0) {
        setUsers(usersData);
        setFilteredUsers(usersData); // Set initial filtered users to all users

        const uniqueStates = [
          ...new Set(
            usersData
              .map((user) => user.altDetails?.STATE?.trim().toLowerCase()) // normalize to lowercase and trim spaces
              .filter(Boolean)
          ),
        ];

        console.log(uniqueStates, "uniqueStates");

        const uniqueSections = [
          ...new Set(
            usersData
              .map((user) => user.altDetails?.SECTION?.trim().toLowerCase())
              .filter(Boolean)
          ),
        ];
        const uniqueEducationQualifications = [
          ...new Set(
            usersData
              .map((user) =>
                user.altDetails?.EDUCATION_QUALIFICATION?.trim().toLowerCase()
              )
              .filter(Boolean)
          ),
        ];

        setEducationQualification(uniqueEducationQualifications);
        setStates(uniqueStates);
        setSections(uniqueSections);
      } else {
        console.log("No user data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Ensure loading state is updated in both success and error cases
    }
  };
  

  // const getData = async () => {
  //   try {
  //     // Fetch user data from the API
  //     const response = await axios.get(`${BASE_URL}/api/v1/user`);
  //     const usersData = response.data?.users || []; // Ensure correct property is accessed
  
  
  
  //     console.log(usersData, "usersData");
  
  //     if (Array.isArray(usersData) && usersData.length > 0) {
  //       // Set user data and filtered users
  //       setUsers(usersData);
  //       setFilteredUsers(usersData); // Initial filtered users are all users
  
  //       // Extract unique states
  //       const uniqueStates = [
  //         ...new Set(
  //           usersData
  //             .map((user) => user?.altDetails?.STATE?.trim()?.toLowerCase())
  //             .filter(Boolean) // Remove undefined/null/empty values
  //         ),
  //       ];
  //       console.log(uniqueStates, "uniqueStates");
  
  //       // Extract unique sections
  //       const uniqueSections = [
  //         ...new Set(
  //           usersData
  //             .map((user) => user?.altDetails?.SECTION?.trim()?.toLowerCase())
  //             .filter(Boolean)
  //         ),
  //       ];
  //       console.log(uniqueSections, "uniqueSections");
  
  //       // Extract unique education qualifications
  //       const uniqueEducationQualifications = [
  //         ...new Set(
  //           usersData
  //             .map((user) =>
  //               user?.altDetails?.EDUCATION_QUALIFICATION?.trim()?.toLowerCase()
  //             )
  //             .filter(Boolean)
  //         ),
  //       ];
  //       console.log(uniqueEducationQualifications, "uniqueEducationQualifications");
  
  //       // Update state variables
  //       setEducationQualification(uniqueEducationQualifications);
  //       setStates(uniqueStates);
  //       setSections(uniqueSections);
  //     } else {
  //       console.log("No user data found.");
  //     }
  //   } catch (error) {
  //     // Log errors for debugging
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     // Ensure loading state is updated regardless of success or error
  //     setIsLoading(false);
  //   }
  // };
  
  const handleStateSelection = (state) => {
    if (selectedStates.includes(state)) {
      setSelectedStates(selectedStates.filter((s) => s !== state));
    } else {
      setSelectedStates([...selectedStates, state]);
    }
  };

  const handleSearch = () => {
    const filteredData = users.filter((item) => {
      const issuedate = new Date(item.altDetails?.ISSUED_DATE);
      const isIssueDate =
        (!startissuedate || issuedate >= new Date(startissuedate)) &&
        (!endissuedate || issuedate <= new Date(endissuedate));

      const dob = new Date(item.altDetails?.dob);
      const isDobInRange =
        (!startDob || dob >= new Date(startDob)) &&
        (!endDob || dob <= new Date(endDob));

      const parchmentNumber = item.altDetails?.PARCHMENT_NO;
      const isParchmentInRange =
        (!startparchmentNumber || parchmentNumber >= startparchmentNumber) &&
        (!endparchmentNumber || parchmentNumber <= endparchmentNumber);

      const isStateSelected =
        selectedStates.length === 0 ||
        selectedStates.includes(item.altDetails?.STATE?.trim().toLowerCase());

      return (
        isParchmentInRange &&
        isIssueDate &&
        isStateSelected &&
        (selectedEducationQualification
          ? item.altDetails?.EDUCATION_QUALIFICATION ===
            selectedEducationQualification
          : true) &&
        (startDob || endDob ? isDobInRange : true) &&
        (startissuedate || endissuedate ? isDobInRange : true) &&
        (uid ? item?.bsgUid === uid : true) &&
        (email ? item?.email === email : true) &&
        (startparchmentNumber || endparchmentNumber
          ? isParchmentInRange
          : true) &&
        (selectedSection
          ? item.altDetails?.SECTION?.trim().toLowerCase() === selectedSection
          : true)
      );
    });
    setIsOpen(false);
    setFilteredUsers(filteredData.length > 0 ? filteredData : users);
    setNoDataFound(filteredData.length === 0);
  };

  // Calculate the current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination controls
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
<>
      <div className="text-2xl font-bold mb-4 pb-5 text-center text-gray-800 uppercase">
        HWB Records
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-1 mb-6 ">
        <div className="relative" ref={dropdownRef}>
          <div className="relative mb-3">
            <label className="block text-sm font-medium text-black uppercase">
              State
            </label>

            <button
              onClick={toggleDropdown}
              className="w-full py-1.5 mt-2 px-3 border border-gray-300 text-sm font-semibold rounded-sm  lowercase "
            >
              <div className="flex justify-between items-center ">
                <span className="text-xs  uppercase">
                  {selectedStates.length > 0
                    ? selectedStates.join(", ")
                    : "Select States"}
                </span>
                <MdOutlineKeyboardArrowDown
                  className={`transform transition-transform duration-200 ${
                    isOpen ? "rotate-180" : ""
                  }`}
                />
              </div>
            </button>

            {isOpen && (
              <div className="absolute z-10 w-full mt-2 bg-white border border-gray-200 rounded-lg ">
                {states.map((state, index) => (
                  <div
                    key={index}
                    onClick={() => handleStateSelection(state)}
                    className={`flex items-center justify-between py-2 px-4 cursor-pointer transition-colors duration-150  hover:bg-blue-50 ${
                      selectedStates.includes(state)
                        ? "bg-blue-300"
                        : "bg-white"
                    }`}
                  >
                    <span className="text-black text-xs uppercase -py-1">
                      {state}
                    </span>
                    {selectedStates.includes(state) && (
                      <span className="text-blue-600 font-bold">✔️</span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-black mb-2 uppercase">
            DOB (From)
          </label>
          <input
            type="date"
            value={startDob}
            onChange={(e) => setStartDob(e.target.value)}
            className="w-full py-1 px-2 border border-gray-300  text-sm rounded-sm shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-black mb-2 uppercase">
            DOB (To)
          </label>
          <input
            type="date"
            value={endDob}
            onChange={(e) => setEndDob(e.target.value)}
            className="w-full py-1 px-2 border border-gray-300  text-sm rounded-sm shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-black mb-2 uppercase">
            ISSUE DATE (From)
          </label>
          <input
            type="date"
            value={startissuedate}
            onChange={(e) => setStartIssueDate(e.target.value)}
            className="w-full py-1 px-2 border border-gray-300  text-sm rounded-sm shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-black mb-2 uppercase">
            ISSUE DATE (To)
          </label>
          <input
            type="date"
            value={endissuedate}
            onChange={(e) => setEndIssueDate(e.target.value)}
            className="w-full py-1 px-2 border border-gray-300  text-sm rounded-sm shadow-sm"
          />
        </div>

        <div className="flex flex-col">
          <label className="block text-sm font-medium  text-black mb-2 uppercase">
            Section
          </label>
          <select
            onChange={(e) => setSelectedSection(e.target.value)}
            value={selectedSection}
            className="w-full py-1 px-2 border border-gray-300  text-sm rounded-sm shadow-sm"
          >
            <option value="">Select Section</option>
            {sections.map((section, index) => (
              <option key={index} value={section}>
                {section}
              </option>
            ))}
          </select>
        </div>

        <div className="">
          <label className="block text-sm font-medium text-black mb-2 ">
            BSG UID
          </label>
          <input
            type="text"
            placeholder="Enter BSG UID"
            value={uid}
            onChange={(e) => setUid(e.target.value)}
            className="w-full py-1 px-2 border border-gray-300  text-sm rounded-sm shadow-sm text-black"
          />
        </div>

        <div className="">
          <label className="block text-sm font-medium text-black mb-2 uppercase ">
            Email ID
          </label>
          <input
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full py-1 px-2 border border-gray-300  text-sm rounded-sm shadow-sm"
          />
        </div>

        <div className="">
          <label className="block text-sm font-medium text-black mb-2 uppercase ">
            Qualification
          </label>
          <select
            value={selectedEducationQualification}
            onChange={(e) => setSelectedEducationQualification(e.target.value)}
            className="w-full py-1 px-2 border border-gray-300  text-sm rounded-sm shadow-sm"
          >
            <option value="">Select Qualification</option>
            {educationQualification.map((educationQualifications, index) => (
              <option key={index} value={educationQualifications}>
                {educationQualifications}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-black mb-2 uppercase">
            PARCHMENT (From)
          </label>
          <input
            type="text"
            placeholder="Enter Number"
            value={startparchmentNumber}
            onChange={(e) => setStartparchmentNumber(e.target.value)}
            className="w-full py-1 px-2 border border-gray-300  text-sm rounded-sm shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-black mb-2 uppercase">
            PARCHMENT (To)
          </label>
          <input
            type="text"
            value={endparchmentNumber}
            placeholder="Enter Number"
            onChange={(e) => setEndparchmentNumber(e.target.value)}
            className=" w-full py-1 px-2 border border-gray-300  text-sm rounded-sm shadow-sm"
          />
        </div>
        <div className="flex items-center justify-between flex-row">
          <button
            onClick={clearAll}
            className="mt-7 py-0.5 px-16 bg-red-500 text-white rounded-sm flex flex-row w-full justify-center"
          >
            Clear <span className="ml-1"> All</span>
          </button>
        </div>
      </div>
      <div className="flex flex-row gap-5 ">
        <button
          onClick={handleSearch}
          className="w-full py-1 px-4 bg-[#1d56a5] text-white rounded-sm shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Search
        </button>
        <button
          onClick={exportToExcel}
          className="w-full py-1 px-4  bg-[#1d56a5] text-white rounded-sm shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Export to Excel
        </button>
      </div>
    {/* <div className="w-auto max-w-4xl ml-10 "> */}
      <div className="flex items-center justify-center my-5 w-auto ">
        {isLoading ? (
          <ClipLoader color="#1D56A5" size={50} />
        ) : (
          <div className=" w-full max-w-4xl mt-4   rounded-lg shadow-lg bg-white overflow-x-auto   ">
            <table className="min-w-full bg-white shadow-md rounded-lg ">
              <thead className="bg-blue-600 text-white">
                <tr>
                  {[
                    "SL",
                    "NAME",
                    "DOB",
                    "BSG UID",
                    "Email",
                    "State",
                    "Section",
                    "QUAL.",
                    "PARCH. NO.",
                    "ISSUED DATE",
                    "Actions",
                  ].map((header, index) => (
                    <th
                      key={index}
                      className="py-1 px-8 text-xs font-semibold uppercase tracking-wide text-center "
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
{/* 
              <tbody className="bg-white divide-y divide-gray-200">


                {currentUsers.map((user, index) => (
                  <tr
                    key={user.id}
                    className="hover:bg-blue-50 transition duration-200 ease-in-out"
                  >
                    <td className="  py-1 px-2 text-xs text-gray-800 text-center">
                      {indexOfFirstItem + index + 1}
                    </td>
                    <td className="py-1 px-2 text-xs text-gray-800 text-center">
                      {user.altDetails?.name}
                    </td>
                    <td className="py-1 px-2 text-xs text-gray-800 text-center">
                      {user.altDetails?.dob}
                    </td>
                    <td className="py-1 px-2 text-xs text-gray-800 text-center">
                      {user?.bsgUid}
                    </td>
                    <td className="py-1 px-2 text-xs text-gray-800 text-center">
                      {user?.email}
                    </td>

                    <td className="py-1 px-2 text-xs text-gray-800 text-center">
                      {user.altDetails?.STATE}
                    </td>
                    <td className="py-1 px-2 text-xs text-gray-800 text-center">
                      {user.altDetails?.SECTION}
                    </td>

                    <td className="py-1 px-2 text-xs text-gray-800 text-center">
                      {user.altDetails?.EDUCATION_QUALIFICATION}
                    </td>
                    <td className="py-1 px-2 text-xs text-gray-800 text-center">
                      {user.altDetails?.PARCHMENT_NO}
                    </td>
                    <td className="py-1 px-2 text-xs text-gray-800 text-center">
                      {user.altDetails?.ISSUED_DATE}
                    </td>

                    <td className="py-1 px-2 text-xs text-gray-800 text-center">
                      <button
                        onClick={() => openModal(user)}
                        className="px-4 py-2 bg-blue-600 text-white text-xs rounded hover:bg-blue-700"
                      >
                        View All
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody> */}
              <tbody className="bg-white divide-y divide-gray-200">
  {noDataFound  ? (
   <tr>
   <td colSpan={11} className="py-4 text-center text-gray-500">
     Data not found
   </td>
 </tr>
  ) : (
    currentUsers.map((user, index) => (
      <tr
        key={user.id}
        className="hover:bg-blue-50 transition duration-200 ease-in-out"
      >
        <td className="py-1 px-2 text-xs text-gray-800 text-center">
          {indexOfFirstItem + index + 1}
        </td>
        <td className="py-1 px-2 text-xs text-gray-800 text-center">
          {user.altDetails?.name}
        </td>
        <td className="py-1 px-2 text-xs text-gray-800 text-center">
          {user.altDetails?.dob}
        </td>
        <td className="py-1 px-2 text-xs text-gray-800 text-center">
          {user?.bsgUid}
        </td>
        <td className="py-1 px-2 text-xs text-gray-800 text-center">
          {user?.email}
        </td>
        <td className="py-1 px-2 text-xs text-gray-800 text-center">
          {user.altDetails?.STATE}
        </td>
        <td className="py-1 px-2 text-xs text-gray-800 text-center">
          {user.altDetails?.SECTION}
        </td>
        <td className="py-1 px-2 text-xs text-gray-800 text-center">
          {user.altDetails?.EDUCATION_QUALIFICATION}
        </td>
        <td className="py-1 px-2 text-xs text-gray-800 text-center">
          {user.altDetails?.PARCHMENT_NO}
        </td>
        <td className="py-1 px-2 text-xs text-gray-800 text-center">
          {user.altDetails?.ISSUED_DATE}
        </td>
        <td className="py-1 px-2 text-xs text-gray-800 text-center">
          <button
            onClick={() => openModal(user)}
            className="px-4 py-2 bg-blue-600 text-white text-xs rounded hover:bg-blue-700"
          >
            View All
          </button>
        </td>
      </tr>
    ))
  )}
</tbody>
            </table>

            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  border: "none",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {selectedUser && (
                <div className="bg-white shadow-md rounded-lg p-6">
                  <h2 className="text-2xl font-bold text-gray-800 mb-6 border-b pb-2 uppercase">
                    User Details
                  </h2>
                  <div className="grid grid-cols-2 gap-4 text-gray-700">
                    <div className="flex">
                      <strong className="w-48 text-black">Name:</strong>
                      <span>{selectedUser.altDetails.name}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">DOB:</strong>
                      <span>{selectedUser.altDetails.dob}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">BSG UID:</strong>
                      <span>{selectedUser.bsgUid}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Email:</strong>
                      <span>{selectedUser.email}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black ">Mobile:</strong>
                      <span>{selectedUser.altDetails.mobile}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">State:</strong>
                      <span>{selectedUser.altDetails.state}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Section:</strong>
                      <span>{selectedUser.altDetails.section}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Unit:</strong>
                      <span>{selectedUser.altDetails.nameofunit}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">
                        Qualification:
                      </strong>
                      <span>{selectedUser.altDetails.scoutqualification}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">
                        Education Qualification:
                      </strong>
                      <span>
                        {selectedUser.altDetails.educstionqualification}
                      </span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Parchment No:</strong>
                      <span>{selectedUser.altDetails.HWB_PARCHMENT_NO}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Issued Date:</strong>
                      <span>{selectedUser.altDetails.ISSUED_DATE}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Address:</strong>
                      <span>{selectedUser.altDetails.address}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Remarks:</strong>
                      <span>{selectedUser.altDetails.REMARKS}</span>
                    </div>
                  </div>
                  <button
                    onClick={closeModal}
                    className="mt-6 px-6 py-2 bg-[#1d56a5] hover:bg-blue-700 text-white rounded-sm transition-all duration-300 ease-in-out"
                  >
                    Close
                  </button>
                </div>
              )}
            </Modal>
          </div>
        )}
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 m-5 bg-blue-600 text-white text-xs rounded hover:bg-blue-700 disabled:opacity-50 transition duration-200"
        >
          Previous
        </button>
        <span className="text-xs text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 m-5 bg-blue-600 text-white text-xs rounded hover:bg-blue-700 disabled:opacity-50 transition duration-200"
        >
          Next
        </button>
      </div>
    {/* </div> */}
    </>
  );
};

export default SearchComponent;
