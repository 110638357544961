import React, {  useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../constant/constant'

const Marque = () => {
    const [marque, setMarque] = useState('')


    const handleUpload = async () => {
        const data = {
            marqueData: marque
        }
        try {
            const storedIdString = localStorage.getItem("_id");
             const userId = JSON.parse(storedIdString);
            const response = await axios.post(`${BASE_URL}/api/v1/addmarque/${userId}`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response.data, "datasss");


            setMarque("");
            alert('Marque added successfully!');

        } catch (error) {
            console.error('Error adding category:', error);
        }
    }




    return (
        <>
           
            <div className='grid grid-cols-7'>
               
                <div className='col-span-4'>
                    <div className=' flex justify-center text-center  font-black text-black  h-20 mt-10 pt-8 md:w-1/2 mx-auto -ml-20' style={{ width: "75vw", fontSize: "20px", fontFamily: "'Nunito Sans', sans-serif" }}>
                        Add Marquee
                    </div>

                    <div className='flex flex-col  ml-10 mt-10 h-screen'>
                        
                        <input
                            type="text"
                            className='px-2 py-1 mb-6 bg-slate-300 outline-0' placeholder='Enter the marque'
                            value={marque}
                            style={{ width: '300px' }}
                            onChange={(e) => { setMarque(e.target.value) }}
                        />
                        <button className='bg-blue-500 text-white px-3 py-2 rounded-lg shadow-lg hover:bg-blue-600' onClick={handleUpload}>
                            Add Marque
                        </button>
                    </div></div>
                </div>
              
        </>

    )
}

export default Marque

