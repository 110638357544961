// export const BASE_URL="http://localhost:3005"
export const BASE_URL="https://bw-tp.bsgindia.tech"
export const POST_USER_REGISTER_HWB=BASE_URL+"/api/v1/hwbuser"
export const POST_USER_REGISTER_ALT=BASE_URL+"/api/v1/altuser"
export const POST_USER_REGISTER_LT=BASE_URL+"/api/v1/ltuser"
export const POST_USER_REGISTER=BASE_URL+"/api/v1/user"
export const POST_USER_EXIST_HWB=BASE_URL+"/api/v1/existuser"
export const POST_USER_lOGIN=BASE_URL+"/api/user/login"
export const POST_SET_PASSWORD=BASE_URL+"/api/user/set-password"

