import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { BASE_URL } from "../../constant/constant";

const AddBSGState = () => {
  const [revenuestate, setRevenueState] = useState("");
  const [states, setStates] = useState([]);

  const handleSubmit = async () => {
    const data = { revenuestate };
    try {
      const userId=localStorage.getItem("id")
      console.log(userId,"userId")
      const response = await axios.post(
        `${BASE_URL}/api/v1/addrevenuestate/${userId}`,
        data
      );
      console.log(response.data);
      toast.success("Revenue State Added Successfully");
      setRevenueState(""); // Reset input field after submission
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getState();
  }, []);

  const getState = async () => {
    try {
      const userId=localStorage.getItem("id")
      console.log(userId,"userId")
      const response = await axios.get(`${BASE_URL}/api/v1/getrevenuestate/${userId}`);
      console.log(response, "response");
      console.log(response.data);
      setStates(response.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log(id,"id");
      const response = await fetch(
        `${BASE_URL}/api/v1/deleterevenuestate/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        console.log("Category deleted successfully");
getState();
        
      } else {
        console.error("Failed to delete category:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <>
      <div className=" pt-1 flex items-center justify-center ">
        <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-sm">
          <h1 className="text-2xl font-bold mb-4 text-center text-gray-800">
           ADD REVENUE STATE
          </h1>
          <ToastContainer />
          <input
            type="text"
            placeholder="Enter the Revenue State"
            value={revenuestate}
            onChange={(e) => setRevenueState(e.target.value)}
            className="border border-gray-300 rounded-md w-full p-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white py-2 px-4 rounded-md w-full hover:bg-blue-600 transition duration-200"
          >
            Submit
          </button>
        </div>
      </div>


            <div className="font-bold text-2xl text-black mb-6 uppercase">All Revenue states</div>
<div className="space-y-4">
  {states.map((item, index) => {
    return (
      <div key={index} className="flex justify-between items-center p-2 bg-white rounded-lg shadow-md hover:bg-gray-50 transition">
        <div className="text-md text-gray-700">{item.revenuestate}</div>
        <button
          className="text-red-500 hover:text-red-700 transition"
          onClick={() => handleDelete(item._id)}
        >
          <MdDelete
            style={{
              fontSize: 24,
            }}
          />
        </button>
      </div>
    );
  })}
</div>

      {/* <div className="font-bold">All Revenue State</div>
      <div>
        {states.map((item, index) => {
          return (
            <>
              <div>{item.revenuestate}</div>
            </>
          );
        })}
      </div> */}

      



      
    </>
  );
};

export default AddBSGState;
