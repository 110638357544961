


import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../constant/constant";

const ActiveALtUser = () => {
  const [active, setActiveValid] = useState([]); // Holds the filtered valid LT user data
  const [filteredData, setFilteredData] = useState([]); // Holds the filtered data after search
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [searchState, setSearchState] = useState(""); // State for filtering by state
  // const [searchChargeNo, setSearchChargeNo] = useState(""); // State for filtering by Honourable Charge No
  // const [searchBsgUid, setSearchBsgUid] = useState(""); // State for filtering by BSG UID
  // const [searchEmail, setSearchEmail] = useState(""); // State for filtering by email
  const [states, setStates] = useState([]); // List of states
  const recordsPerPage = 50; // Number of records per page
  const token = localStorage.getItem("adminToken");
  const [selectedUser , setSelectedUser ] = useState(null); 

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const handleView = (userId) => {
    const user = filteredData.find(item => item._id === userId);
    setSelectedUser (user); // Set the selected user to show in the modal
  };

  const handleCloseModal = () => {
    setSelectedUser (null); // Close the modal
  };
  useEffect(() => {
    getALt();
  }, []);

  const getALt = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/altuser`, axiosConfig);
      // Extract states dynamically from the data
      const stateList = [...new Set(response.data.map(item => item.STATE))];
      console.log(stateList,"stateList")
      setStates(stateList);

      // Filtering valid data
      const validData = response.data.filter(
        (item) =>
          item.STATUS === "Active" &&
          (item.HONOURABLE_CHARGE_NO || item.honourableNumber)
      );
      

      setActiveValid(validData); // Update state with valid data
      setFilteredData(validData); // Initially, show all valid data
    } catch (error) {
      console.error("Error fetching LT data:", error);
    }
  };

  // Filter function based on search fields
  const handleSearch = () => {
    const result = active.filter((item) => {
      const matchesState = searchState ? item.STATE === searchState : true;
  
      // const matchesChargeNo = searchChargeNo
      //   ? item.HONOURABLE_CHARGE_NO && item.HONOURABLE_CHARGE_NO.toLowerCase().includes(searchChargeNo.toLowerCase())
      //   : true;
  
      // const matchesBsgUid = searchBsgUid
      //   ? item.bsgUid && item.bsgUid.toLowerCase().includes(searchBsgUid.toLowerCase())
      //   : true;
  
      // const matchesEmail = searchEmail
      //   ? item.email && item.email.toLowerCase().includes(searchEmail.toLowerCase())
      //   : true;
  
      return matchesState;
    });
  console.log(result,"result")
    setFilteredData(result); // Update filtered data
    setCurrentPage(1); // Reset to the first page when search is triggered
  };

  // Pagination logic
  const totalPages = Math.ceil(filteredData.length / recordsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  return (
    <div className="">
      <h1 className="text-2xl font-bold mb-4 uppercase text-center text-[#1D56A5]">
        Active ALT Users
      </h1>

      <div className="flex flex-row gap-4 mb-6">
        {/* <input
          type="text"
          placeholder="Search by Honourable Charge No"
          value={searchChargeNo}
          onChange={(e) => setSearchChargeNo(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        />
        <input
          type="text"
          placeholder="Search by BSG UID"
          value={searchBsgUid}
          onChange={(e) => setSearchBsgUid(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        />
        <input
          type="email"
          placeholder="Search by Email"
          value={searchEmail}
          onChange={(e) => setSearchEmail(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        /> */}

        {/* Dropdown for States */}
        <select
          value={searchState}
          onChange={(e) => setSearchState(e.target.value)}
          className="p-2 border border-gray-300 rounded w-64"
        >
          <option value="">Select State</option>
          {states.map((state) => (
            <option key={state._id} value={state}>
              {state}
            </option>
          ))}
        </select>

        {/* Search Button */}
        <button
          onClick={handleSearch} // Trigger filtering on button click
          className="bg-yellow-500 text-white p-2 rounded"
        >
          Search
        </button>
      </div>

      {filteredData.length === 0 ? (
        <p className="text-center text-gray-500">No valid LT users found.</p>
      ) : (
        <>
          <table className="w-full border-collapse border border-gray-300 shadow-lg rounded-md overflow-hidden">
            <thead className="bg-[#1D56A5] text-white ">
              <tr>
              <th className="border border-gray-300 py-2 text-xs">No.</th>
                <th className="border border-gray-300 py-2  text-xs">Name</th>
                <th className="border border-gray-300 py-2 text-xs">Email</th>
                <th className="border border-gray-300 py-2 text-xs">DOB</th>
                <th className="border border-gray-300 py-2 text-xs">BSG UID</th>
                <th className="border border-gray-300 py-2 text-xs">Honourable Charge No</th>
                <th className="border border-gray-300 py-2 text-xs">State</th>
                <th className="border border-gray-300 py-2  text-xs">Renewed_upto</th>
                <th className="border border-gray-300 py-2  text-xs">Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => (
                <tr
                  key={item._id}
                  className={index % 2 === 0 ? "bg-gray-50" : "bg-white hover:bg-gray-100"}
                >
                  <th className="border border-gray-300 py-2  text-xs">{index+1}</th>
                  <td className="border border-gray-300 py-2  text-xs">{item.name || "N/A"}</td>
                  <td className="border border-gray-300 py-2 text-xs">{item.email}</td>
                  <td className="border border-gray-300 py-2 text-xs">{item.dob}</td>
                  <td className="border border-gray-300 py-2 text-xs">{item.bsgUid}</td>
                  <td className="border border-gray-300 py-2 text-xs">{item.HONOURABLE_CHARGE_NO  || item.honourableNumber}</td>
                  <td className="border border-gray-300 py-2 text-xs">{item.STATE}</td>
                  <td className="border border-gray-300 py-2 text-xs">{item.RENEWED_UPTO}</td>
                  <td className="border border-gray-300 py-2 text-xs cursor-pointer text-center font-medium text-red-600" onClick={()=>handleView(item._id)}>View All</td>
                </tr>
              ))}
            </tbody>
          </table>

          {selectedUser && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-xl">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 border-b pb-3">User Details</h2>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="text-sm  text-black font-bold">Name</p>
          <p className="text-gray-800">{selectedUser.name || "N/A"}</p>
        </div>
        <div>
          <p className="text-sm  text-black font-bold">Email</p>
          <p className="text-gray-800">{selectedUser.email}</p>
        </div>
        <div>
          <p className="text-sm  text-black font-bold">DOB</p>
          <p className="text-gray-800">{selectedUser.dob}</p>
        </div>
        <div>
          <p className="text-sm  text-black font-bold">BSG UID</p>
          <p className="text-gray-800">{selectedUser.bsgUid}</p>
        </div>
        <div>
          <p className="text-sm  text-black font-bold">Honourable Charge No</p>
          <p className="text-gray-800">{selectedUser.HONOURABLE_CHARGE_NO || selectedUser.honourableNumber}</p>
        </div>
        <div>
          <p className="text-sm  text-black font-bold">State</p>
          <p className="text-gray-800">{selectedUser.STATE}</p>
        </div>
        <div>
          <p className="text-sm  text-black font-bold">Status</p>
          <p className="text-gray-800">{selectedUser.STATUS}</p>
        </div>
        <div>
          <p className="text-sm  text-black font-bold">Region</p>
          <p className="text-gray-800">{selectedUser.REGION}</p>
        </div>
        <div>
          <p className="text-sm  text-black font-bold">Issued Date</p>
          <p className="text-gray-800">{selectedUser.DATE}</p>
        </div>
        <div>
          <p className="text-sm  text-black font-bold">Admin Status</p>
          <p className="text-gray-800">{selectedUser.status}</p>
        </div>
        <div>
          <p className="text-sm  text-black font-bold">Renewed Upto</p>
          <p className="text-gray-800">{selectedUser.RENEWED_UPTO}</p>
        </div>
      </div>
      <div className="mt-6 flex justify-end">
        <button
          onClick={handleCloseModal}
          className="bg-[#1D56A5] text-white py-2 px-4 rounded-lg shadow"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}


          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className={` py-1 px-2 rounded ${currentPage === 1 ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-[#1D56A5] text-white hover:bg-blue-700"}`}
            >
              Previous
            </button>
            <span className="text-gray-700">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className={` py-1 px-2 rounded ${currentPage === totalPages ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-[#1D56A5] text-white hover:bg-blue-700"}`}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ActiveALtUser;
