import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { BASE_URL } from "../constant/constant";
import CountUp from "react-countup";

const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [altCount, setAltCount] = useState(0);
  const [ltCount, setLtCount] = useState(0);
  const [validDataLength, setValidDataLength] = useState(0);
  const [validDataLength1, setValidDataLength1] = useState(0);
  const [loaderPercentage, setLoaderPercentage] = useState(0);
  const [loaderColor, setLoaderColor] = useState("bg-gray-200");
  const [role, setUserRole] = useState("");
  const [assignment, setUserAssignment] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
   const [usersData, setUsers] = useState([]);
   const [usersData1, setUsers1] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [currentViewState, setCurrentViewState] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filterType, setFilterType] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSending, setIsSending] = useState(false); 
    const [selectedUser, setSelectedUser] = useState(null); 
  const [totals, setTotals] = useState({
      valid: 0,
      invalid: 0,
      validKYTScout: 0,
      validKYTGuide: 0,
      validKYTCub: 0,
      validKYTBulbul: 0,
      validKYTRover: 0,
      validKYTRanger: 0,
      HC_VALID_AND_KYT_DONE: 0,
      HC_valid_But_KYT_Not_Done: 0,
      HC_INVALID_BUT_KYT_DONE: 0,
    });
    const [totals1, setTotals1] = useState({
      valid: 0,
      invalid: 0,
      validKYTScout: 0,
      validKYTGuide: 0,
      validKYTCub: 0,
      validKYTBulbul: 0,
      validKYTRover: 0,
      validKYTRanger: 0,
      HC_VALID_AND_KYT_DONE: 0,
      HC_valid_But_KYT_Not_Done: 0,
      HC_INVALID_BUT_KYT_DONE: 0,
    });
  const navigate = useNavigate();

  const token = localStorage.getItem("adminToken");

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,


      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    getLt();
    getALt();
  }, []);

  const getLt = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/ltuser`,
        axiosConfig
      );

      // Assuming the data is an array
      const validData = response.data.filter(
        (item) =>
          item.STATUS === "Active" &&
          (item.HONOURABLE_CHARGE_NO || item.honourableNumber)
      );
      console.log(validData);
      setValidDataLength(validData.length);
      // Logs only the valid data
    } catch (error) {
      console.error("Error fetching ALT data:", error);
    }
  };

  const getALt = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/altuser`,
        axiosConfig
      );

      // Assuming the data is an array
      const validData = response.data.filter(
        (item) =>
          item.STATUS === "Active" &&
          (item.HONOURABLE_CHARGE_NO || item.honourableNumber)
      );
      console.log(validData);
      setValidDataLength1(validData.length);
      // Logs only the valid data
    } catch (error) {
      console.error("Error fetching ALT data:", error);
    }
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("dashboardData"));

    if (storedData) {
      setUserCount(storedData.userCount);
      setAltCount(storedData.altCount);
      setLtCount(storedData.ltCount);
      setInitialLoading(false);
    } else {
      const totalAPIs = 3;
      let completedAPIs = 0;

      const updateLoader = () => {
        completedAPIs++;
        setLoaderPercentage((completedAPIs / totalAPIs) * 100);
        if (completedAPIs === 1) setLoaderColor("bg-yellow-500");
        if (completedAPIs === 2) setLoaderColor("bg-yellow-500");
        if (completedAPIs === 3) setLoaderColor("bg-yellow-500");
      };

      const tempData = { userCount: 0, altCount: 0, ltCount: 0 };

      Promise.all([
        axios
          .get(`${BASE_URL}/api/v1/hwbuser`, axiosConfig)
          .then((response) => {
            tempData.userCount = response.data.length;
            setUserCount(response.data.length);
            updateLoader();
          })
          .catch(() => {
            toast.error("Error fetching user data.");
          }),
        axios
          .get(`${BASE_URL}/api/v1/altuser`, axiosConfig)
          .then((response) => {
            tempData.altCount = response.data.length;
            setAltCount(response.data.length);
            updateLoader();
          })
          .catch(() => {
            toast.error("Error fetching ALT user data.");
          }),
        axios
          .get(`${BASE_URL}/api/v1/ltuser`, axiosConfig)
          .then((response) => {
            tempData.ltCount = response.data.length;
            setLtCount(response.data.length);
            updateLoader();
          })
          .catch(() => {
            toast.error("Error fetching LT user data.");
          }),
      ])
        .then(() => {
          localStorage.setItem("dashboardData", JSON.stringify(tempData));
        })
        .finally(() => {
          setInitialLoading(false);
        });
    }

    axios
      .get(
        `${BASE_URL}/api/v1/currentUser/${localStorage.getItem("id")}`,
        axiosConfig
      )
      .then((response) => {
        const currentUser = response.data;
        setUserRole(currentUser.role);
        setUserAssignment(
          currentUser.assignment && currentUser.assignment[0]
            ? currentUser.assignment[0]
            : ""
        );
      })
      .catch(() => {
        toast.error("Error fetching current user data.");
      });
    // }, []);

    axios
      .get(
        `${BASE_URL}/api/v1/currentUser/${localStorage.getItem("id")}`,
        axiosConfig
      )
      .then((response) => {
        const currentUser = response.data;
        console.log(currentUser, "currentuser");

        setUserRole(currentUser.role);
        setUserAssignment(
          currentUser.assignment && currentUser.assignment[0]
            ? currentUser.assignment[0]
            : ""
        );
      })
      .catch(() => {
        toast.error("Error fetching current user data.");
      });
  }, []);

  const cardData = [
    {
      label: "HWB Holder",
      count: userCount,
      color: "bg-teal-500",
      route: "/hwblist",
      active: 0,
    },
 
    {
      label: "Alt Holder",
      count: altCount,
      color: "bg-purple-500",
      route: "/altlist",
      active: totals1.HC_VALID_AND_KYT_DONE,
      activeroute: "/showaltliststatedashboard",
    },
    {
      label: "LT Holder",
      count: ltCount,
      color: "bg-red-500",
      route: "/ltlist",
      active: totals.HC_VALID_AND_KYT_DONE,
      activeroute: "/showltliststatedashboard",
    },
  ];

  const data = {
    labels: cardData.map((item) => item.label),
    datasets: [
      {
        label: "Count",
        data: cardData.map((item) => item.count),
        backgroundColor: ["#4A90E2", "#E94E77", "#F8B133", "#AF67E9"],
        borderRadius: 6,
        barThickness: 30,
      },
    ],
  };
  

  useEffect(() => {
    getData();
    getData1()
  }, []);

  const getData1 = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URL}/api/v1/altuser`,
        axiosConfig
      );
      const users = response.data;
   

      setUsers1(users);
      if (!Array.isArray(users)) {
        throw new Error("Fetched data is not an array");
      }

      const states = Array.from(
        new Set(users.map((user) => user.STATE || "Unknown"))
      );

      const aggregatedData = states.map((state) => {
        const today = new Date();

       
       const formattedToday= today.setHours(0, 0, 0, 0);
     console.log(formattedToday,"dfdjghfdg")
        
        const stateUsers = users.filter((user) => user.STATE === state);
        
        const scoutUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Scout"
        );
        const guideUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Guide"
        );
        const cubUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Cub"
        );
        const bulbulUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Bulbul"
        );
        const roversUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Rover"
        );
        const rangerUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Ranger"
        );
   
 

        const validUsers = stateUsers?.filter((user) => {
          const Dates = convertDateString(user.RENEWED_UPTO);
          const validDate = new Date(Dates);
          console.log(validDate,"validDate")
          return validDate && validDate > formattedToday;
        });


        const invalidUsers = stateUsers.filter(
          (user) => convertDateString(user.RENEWED_UPTO) < formattedToday
        );

        const HC_VALID_AND_KYT_DONE = validUsers.filter(
          (user) =>
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const valid = validUsers.length;
        const invalid = invalidUsers.length;

        const HC_valid_But_KYT_Not_Done = validUsers.filter(
          (user) =>
            !(
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            )
        ).length;

        const HC_INVALID_BUT_KYT_DONE = invalidUsers.filter(
          (user) =>
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTScout = scoutUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTGuide = guideUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTCub = cubUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTBulbul = bulbulUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTRover = roversUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTRanger = rangerUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        return {
          state,
          valid,
          validKYTScout,
          invalid,
          stateUsers,
          guideUsers,
          scoutUsers,
          cubUsers,
          bulbulUsers,
          roversUsers,
          rangerUsers,
          validKYTBulbul,
          validKYTCub,
          validKYTRanger,
          validKYTRover,
          validKYTGuide,
          HC_valid_But_KYT_Not_Done,
          HC_INVALID_BUT_KYT_DONE,
          HC_VALID_AND_KYT_DONE,
        };
      });

      // Sort the aggregated data by state in ascending order
      aggregatedData.sort((a, b) => a.state.localeCompare(b.state));

      // Set the sorted state data
      setStateData(aggregatedData);
      // Calculate totals
      const totals1 = aggregatedData.reduce(
        (acc, data) => {
          acc.valid += data.valid;
          acc.invalid += data.invalid;
          acc.validKYTScout += data.validKYTScout;
          acc.validKYTGuide += data.validKYTGuide;
          acc.validKYTCub += data.validKYTCub;
          acc.validKYTBulbul += data.validKYTBulbul;
          acc.validKYTRover += data.validKYTRover;
          acc.validKYTRanger += data.validKYTRanger;
          acc.HC_VALID_AND_KYT_DONE += data.HC_VALID_AND_KYT_DONE;
          acc.HC_valid_But_KYT_Not_Done += data.HC_valid_But_KYT_Not_Done;
          acc.HC_INVALID_BUT_KYT_DONE += data.HC_INVALID_BUT_KYT_DONE;
          return acc;
        },
        {
          valid: 0,
          invalid: 0,
          validKYTScout: 0,
          validKYTGuide: 0,
          validKYTCub: 0,
          validKYTBulbul: 0,
          validKYTRover: 0,
          validKYTRanger: 0,
          HC_VALID_AND_KYT_DONE: 0,
          HC_valid_But_KYT_Not_Done: 0,
          HC_INVALID_BUT_KYT_DONE: 0,
        }
      );

      setTotals1(totals1);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URL}/api/v1/ltuser`,
        axiosConfig
      );
      const users = response.data;
   

      setUsers(users);
      if (!Array.isArray(users)) {
        throw new Error("Fetched data is not an array");
      }

      const states = Array.from(
        new Set(users.map((user) => user.STATE || "Unknown"))
      );

      const aggregatedData = states.map((state) => {
        const today = new Date();

       
       const formattedToday= today.setHours(0, 0, 0, 0);
     console.log(formattedToday,"dfdjghfdg")
        
        const stateUsers = users.filter((user) => user.STATE === state);
        
        const scoutUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Scout"
        );
        const guideUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Guide"
        );
        const cubUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Cub"
        );
        const bulbulUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Bulbul"
        );
        const roversUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Rover"
        );
        const rangerUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Ranger"
        );
   
 

        const validUsers = stateUsers?.filter((user) => {
          const Dates = convertDateString(user.RENEWED_UPTO);
          const validDate = new Date(Dates);
          console.log(validDate,"validDate")
          return validDate && validDate > formattedToday;
        });


        const invalidUsers = stateUsers.filter(
          (user) => convertDateString(user.RENEWED_UPTO) < formattedToday
        );

        const HC_VALID_AND_KYT_DONE = validUsers.filter(
          (user) =>
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const valid = validUsers.length;
        const invalid = invalidUsers.length;

        const HC_valid_But_KYT_Not_Done = validUsers.filter(
          (user) =>
            !(
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            )
        ).length;

        const HC_INVALID_BUT_KYT_DONE = invalidUsers.filter(
          (user) =>
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTScout = scoutUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTGuide = guideUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTCub = cubUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTBulbul = bulbulUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTRover = roversUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTRanger = rangerUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        return {
          state,
          valid,
          validKYTScout,
          invalid,
          stateUsers,
          guideUsers,
          scoutUsers,
          cubUsers,
          bulbulUsers,
          roversUsers,
          rangerUsers,
          validKYTBulbul,
          validKYTCub,
          validKYTRanger,
          validKYTRover,
          validKYTGuide,
          HC_valid_But_KYT_Not_Done,
          HC_INVALID_BUT_KYT_DONE,
          HC_VALID_AND_KYT_DONE,
        };
      });

      // Sort the aggregated data by state in ascending order
      aggregatedData.sort((a, b) => a.state.localeCompare(b.state));

      // Set the sorted state data
      setStateData(aggregatedData);
      // Calculate totals
      const totals = aggregatedData.reduce(
        (acc, data) => {
          acc.valid += data.valid;
          acc.invalid += data.invalid;
          acc.validKYTScout += data.validKYTScout;
          acc.validKYTGuide += data.validKYTGuide;
          acc.validKYTCub += data.validKYTCub;
          acc.validKYTBulbul += data.validKYTBulbul;
          acc.validKYTRover += data.validKYTRover;
          acc.validKYTRanger += data.validKYTRanger;
          acc.HC_VALID_AND_KYT_DONE += data.HC_VALID_AND_KYT_DONE;
          acc.HC_valid_But_KYT_Not_Done += data.HC_valid_But_KYT_Not_Done;
          acc.HC_INVALID_BUT_KYT_DONE += data.HC_INVALID_BUT_KYT_DONE;
          return acc;
        },
        {
          valid: 0,
          invalid: 0,
          validKYTScout: 0,
          validKYTGuide: 0,
          validKYTCub: 0,
          validKYTBulbul: 0,
          validKYTRover: 0,
          validKYTRanger: 0,
          HC_VALID_AND_KYT_DONE: 0,
          HC_valid_But_KYT_Not_Done: 0,
          HC_INVALID_BUT_KYT_DONE: 0,
        }
      );

      setTotals(totals);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const convertDateString = (dateString) => {
    const parts = dateString?.split("-");
    // if (parts.length !== 3) return null;
    if (!parts || parts.length !== 3) {
      return null;
  }
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month, day);

    return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day ? date : null;
  };

  const handleSendingEmail = async (email) => {
    try {
      setIsSending(true)
      const response = await axios.post(`${BASE_URL}/api/v1/sendEmail`, { email }, axiosConfig);
      const result = response.data;
  
      if (result.success) {
        toast.success("Email sent successfully!");
      } else {
        toast.error("Failed to send email: " + result.message);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("An error occurred while sending the email.");
    }
    setIsSending(false)
  };






  const handleViewDetails = (state, type) => {
    const stateUsers =
      stateData.find((data) => data.state === state)?.stateUsers || [];
    const scoutUsers =
      stateData.find((data) => data.state === state)?.scoutUsers || [];
    const guideUsers =
      stateData.find((data) => data.state === state)?.guideUsers || [];
    const cubUsers =
      stateData.find((data) => data.state === state)?.cubUsers || [];
    const bulbulUsers =
      stateData.find((data) => data.state === state)?.bulbulUsers || [];
    const roversUsers =
      stateData.find((data) => data.state === state)?.roversUsers || [];
    const rangerUsers =
      stateData.find((data) => data.state === state)?.rangerUsers || [];

    let filtered = [];
    const today = new Date();

       
    const formattedToday= today.setHours(0, 0, 0, 0);

    
  if (state === "All States") {
    // Handle the case for all states
    filtered = usersData.filter((user) => {
      switch (type) {
        case "valid":
          return convertDateString(user.RENEWED_UPTO) > formattedToday;
          
        case "invalid":
          return convertDateString(user.RENEWED_UPTO) < formattedToday;
        case "HC_INVALID_BUT_KYT_DONE":
          return (
            convertDateString(user.RENEWED_UPTO) < formattedToday &&
            
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            
          );

          case "HC_VALID_AND_KYT_DONE":
        filtered = stateUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        );
          case "HC_valid_But_KYT_Not_Done":
          return (
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            
              !(user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
              )
          );

          
      
          


      
        default:
          return false;
      }
    });
  } else {


 
    switch (type) {
      case "valid":
        filtered = stateUsers.filter(
          (user) => convertDateString(user?.RENEWED_UPTO) > formattedToday
        );
        break;

      case "HC_VALID_AND_KYT_DONE":
        filtered = stateUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        );
        break;

      case "HC_valid_But_KYT_Not_Done":
        filtered = stateUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            !(
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            )
        );
        break;

      case "HC_INVALID_BUT_KYT_DONE":
        filtered = stateUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) < formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        );
        break;
      case "validKYTScout":
        filtered = scoutUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        );
        break;
      case "validKYTGuide":
        filtered = guideUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        );
        break;
      case "validKYTCub":
        filtered = cubUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        );
        break;
      case "validKYTBulbul":
        filtered = bulbulUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        );
        break;
      case "validKYTRover":
        filtered = roversUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        );
        break;
      case "validKYTRanger":
        filtered = rangerUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        );
        break;
      case "invalid":
        filtered = stateUsers.filter(
          (user) => convertDateString(user.RENEWED_UPTO) < formattedToday
        );
        break;

      default:
        break;
    }
  }
    setCurrentViewState(state);
    setFilterType(type);
    setFilteredUsers(filtered);
  };

  const handleBackToDashboard = () => {
    setCurrentViewState(null);
    setFilterType(null);
    setFilteredUsers([]);
  };
  const handleCloseModal = () => {
    setSelectedUser(null); // Close the modal
  };

  const handleView = (userId) => {
    console.log(userId,"userId")
    const user = filteredUsers.find((user) => user._id === userId);
    console.log(user,"user")
    setSelectedUser(user); // Set the selected user
  };





  const renderStateTable = () => (

    <>
    <div className="max-h-[400px] overflow-y-auto">
      <table className="min-w-full bg-white border rounded-lg shadow-lg">
        <thead className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white">
          <tr>
            {[
              "State",
              "Valid",
              "Invalid",
              "Valid-KYT",
              "Invalid+KYT",
              "Valid+KYT",
              "Scout",
              "Guide",
              "Cub",
              "Bulbul",
              "Rover",
              "Ranger",
            ].map((header) => (
              <th
                key={header}
                className="px-2 py-1 text-left text-xs font-semibold uppercase sticky top-0 bg-gradient-to-r from-indigo-600 to-purple-600"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(stateData) && stateData.length > 0 ? (
            stateData.map((data, index) => (
              <tr
                key={index}
                className="hover:bg-gray-100 transition-all duration-200 border-b last:border-none"
              >
                <td className="px-4 py-1 text-gray-700 font-medium text-xs">
                  {data.state}
                </td>
                <td
                  className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                  onClick={() => handleViewDetails(data.state, "valid")}
                >
                  {data.valid}
                </td>
                <td
                  className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                  onClick={() => handleViewDetails(data.state, "invalid")}
                >
                  {data.invalid}
                </td>
                <td
                  className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                  onClick={() => handleViewDetails(data.state, "HC_valid_But_KYT_Not_Done")}
                >
                  {data.HC_valid_But_KYT_Not_Done}
                </td>
                <td
                  className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                  onClick={() =>
                    handleViewDetails(data.state, "HC_INVALID_BUT_KYT_DONE")
                  }
                >
                  {data.HC_INVALID_BUT_KYT_DONE}
                </td>
                <td
                  className="px-4 py-1 cursor-pointer hover:text-blue-500 bg-red-500 text-white"
                  onClick={() => handleViewDetails(data.state, "HC_VALID_AND_KYT_DONE")}
                >
                  {data.HC_VALID_AND_KYT_DONE}
                </td>
                <td
                  className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                  onClick={() => handleViewDetails(data.state, "validKYTScout")}
                >
                  {data.validKYTScout}
                </td>
                <td
                  className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                  onClick={() => handleViewDetails(data.state, "validKYTGuide")}
                >
                  {data.validKYTGuide}
                </td>
                <td
                  className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                  onClick={() => handleViewDetails(data.state, "validKYTCub")}
                >
                  {data.validKYTCub}
                </td>
                <td
                  className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                  onClick={() =>
                    handleViewDetails(data.state, "validKYTBulbul")
                  }
                >
                  {data.validKYTBulbul}
                </td>
                <td
                  className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                  onClick={() => handleViewDetails(data.state, "validKYTRover")}
                >
                  {data.validKYTRover}
                </td>
                <td
                  className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                  onClick={() =>
                    handleViewDetails(data.state, "validKYTRanger")
                  }
                >
                  {data.validKYTRanger}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12" className="text-center">
                No data available
              </td>
            </tr>
          )}

          <tr className="font-bold bg-gray-200 sticky bottom-0">
            <td className="px-4 py-1 text-gray-700">Total</td>
            <td className="px-4 py-1 text-gray-700 cursor-pointer" onClick={() => handleViewDetails("All States", "valid")}>{totals.valid}</td>
            <td className="px-4 py-1 text-gray-700 cursor-pointer" onClick={() => handleViewDetails("All States", "invalid")} >{totals.invalid}</td>
            <td className="px-4 py-1 text-gray-700 cursor-pointer" onClick={() => handleViewDetails("All States", "HC_valid_But_KYT_Not_Done")}>{totals.HC_valid_But_KYT_Not_Done}</td>
            <td className="px- 4 py-1 text-gray-700 cursor-pointer" onClick={() => handleViewDetails("All States", "HC_INVALID_BUT_KYT_DONE")}>
              {totals.HC_INVALID_BUT_KYT_DONE}
            </td>
            <td className="px-4 py-1 text-gray-700 cursor-pointer" onClick={() => handleViewDetails("All States", "HC_VALID_AND_KYT_DONE")}>{totals.HC_VALID_AND_KYT_DONE}</td>
            <td className="px-4 py-1 text-gray-700">{totals.validKYTScout}</td>
            <td className="px-4 py-1 text-gray-700">{totals.validKYTGuide}</td>
            <td className="px-4 py-1 text-gray-700">{totals.validKYTCub}</td>
            <td className="px-4 py-1 text-gray-700">{totals.validKYTBulbul}</td>
            <td className="px-4 py-1 text-gray-700">{totals.validKYTRover}</td>
            <td className="px-4 py-1 text-gray-700">{totals.validKYTRanger}</td>
          </tr>
       
        </tbody>
      </table>
     

    </div>

     <h1 className="text-md font-bold  text-gray-800 mb-2 mt-3">Send Email for all trainers whose Hounourable Charge renewal is due</h1>
<div 
  className="bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 rounded-md px-2 py-1 w-36 text-center text-white font-semibold cursor-pointer shadow-lg transform transition duration-300 hover:scale-105" 
  onClick={() => handleSendingEmail(HC_INVALID_BUT_KYT_DONEEmails)}

>
{isSending ? 'Sending...' : 'Send Email'}

</div>


     </>
  );


  const renderFilteredUsersTable = () => (
    <div className="">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center mb-3">
  <div>
    <h2 className="text-md font-bold text-gray-800  uppercase tracking-wide">
      {`${currentViewState}`}
    </h2>
    <h2 className="text-md font-bold text-gray-800">
      {`(${filterType.toUpperCase()})`}
    </h2>
  </div>
  <div className="flex justify-end">
    <button
      className="px-6 py-2 bg-[#1D56A5] text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-400 transition-all duration-300"
      onClick={handleBackToDashboard}
    >
      Back to Dashboard
    </button>
  </div>
</div>


      <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gradient-to-r from-green-600 to-teal-600 text-white">
          <tr>
            {["No","Name", "State", "Section", "Renewed Upto","Email","Action"].map((header) => (
              <th
                key={header}
                className="px-4 py-1 text-left text-sm font-semibold uppercase"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? (
            filteredUsers.map((user, index) => (
              <tr
                key={index}
                className="hover:bg-gray-100 transition-all duration-200 border-b last:border-none"
              >
                <td className="px-1 py-1 text-gray-700  text-xs font-medium">
                  {index+1}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.name || "N/A"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.STATE || "Unknown"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.SECTION || "N/A"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.RENEWED_UPTO || "22-09-2029"}
                </td>

                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.email}
                </td>
                <td className="px-1 py-1 text-red-500 text-xs  font-bold cursor-pointer" onClick={()=>handleView(user._id)}>
                  View All
                </td>

              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No users found
              </td>
            </tr>
          )}
        </tbody>
      </table>
     
    </div>
  );

  const today = new Date();

       
    const formattedToday= today.setHours(0, 0, 0, 0);
  const HC_INVALID_BUT_KYT_DONEEmails = usersData
    .filter(user => 
      convertDateString(user.RENEWED_UPTO) < formattedToday &&
      user.dob &&
      user.dob !== "NA" &&
      user.bsgUid &&
      user.bsgUid !== "NA" &&
      user.email &&
      user.email !== "NA"
    )
    .map(user => user.email);




  return (
    <div className="min-h-screen p-4">
      <ToastContainer />
      {initialLoading ? (
        <div className="flex items-center justify-center h-screen bg-gray-100 ">
          <div className="relative w-56 h-56 -mt-32">
            {/* Circle background */}
            <div className="absolute inset-0 rounded-full border-8 border-gray-300"></div>

            {/* Loader circle */}
            <div
              className={`absolute inset-0 rounded-full border-8 ${loaderColor}`}
              style={{
                clipPath: "inset(0 50% 0 0)",
                transform: `rotate(${(loaderPercentage / 100) * 360}deg)`,
                transformOrigin: "50% 50%",
              }}
            ></div>

            {/* Center text */}
            <div className="absolute inset-0 flex items-center justify-center text-center">
              <span className="text-lg font-semibold text-gray-800 animate-pulse">
                Loading... {Math.round(loaderPercentage)}%
              </span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {cardData.map((item, index) => (
              <div
                key={index}
                className={`bg-gradient-to-r ${item.color} text-white rounded-lg shadow-lg p-5 h-56`}
              >
                <div className="text-center font-bold text-lg mb-2 uppercase">
                  {item.label}
                </div>
                <div className="text-center text-4xl font-extrabold mb-2 cursor-pointer" 
                  onClick={() => navigate(item.activeroute)}
                 >
                  <CountUp
                    start={0}
                    end={item.active}
                    duration={3}
                    separator=","
                  />
                </div>

                <h1
                  className="bg-white text-blue-500 font-semibold  flex text-center justify-center px-3 rounded-full shadow-md hover:bg-blue-100 cursor-pointer"
                  // onClick={() => navigate(item.activeroute)}
                  onClick={() => navigate(item.route)}
                >
                  Total List: {item.count}
                </h1>
                <div className="flex justify-around mt-4">
                  {/* <button
                    className="bg-white text-blue-500 font-semibold  px-3 rounded-full shadow-md hover:bg-blue-100"
                    onClick={() => navigate(item.route)}
                  >
                    List
                  </button> */}
                  <button
                    className="bg-white text-blue-500 font-semibold text-xs  px-3 rounded-full shadow-md hover:bg-blue-100"
                    onClick={() =>
                      navigate(`/show${item.route.slice(1)}dashboard`)
                    }
                  >
                    Dashboard
                  </button>
               
                </div>
                <button
                    className="m-4 bg-white text-blue-500 font-semibold text-xs py-1 flex text-center justify-center px-2 rounded-full shadow-md hover:bg-blue-100 cursor-pointer"
                    onClick={() =>
                      navigate(`/show${item.route.slice(1)}statedashboard`)
                    }
                  >
                    State Dashboard
                  </button>
              </div>
            ))}
            {role === "superadmin" && (
              <div className="flex-shrink-0 bg-gradient-to-r text-white rounded-lg shadow-lg p-4 w-full h-56 bg-lime-400">
                <div className="text-center font-bold text-lg mb-2 uppercase">
                  Create Admin
                </div>
                <div
                  className="text-center font-semibold"
                  onClick={() => {
                    navigate("/create-admin");
                  }}
                >
                  <div className="space-y-2">
                    <span
                      className="text-white cursor-pointer rounded-md px-3 text-md uppercase bg-gradient-to-r from-red-500 to-red-700 shadow-md hover:shadow-lg hover:from-red-600 hover:to-red-800 transition-all"
                      style={{ fontFamily: "Roboto Mono, monospace" }}
                    >
                      NTC Uploader
                    </span>
                    <br />
                    <span
                      className="text-white cursor-pointer rounded-md px-3 text-md uppercase bg-gradient-to-r from-red-500 to-red-700 shadow-md hover:shadow-lg hover:from-red-600 hover:to-red-800 transition-all"
                      style={{ fontFamily: "Roboto Mono, monospace" }}
                    >
                      NHQ Checker
                    </span>
                    <br />
                    <span
                      className="text-white cursor-pointer rounded-md px-3 text-md uppercase bg-gradient-to-r from-red-500 to-red-700 shadow-md hover:shadow-lg hover:from-red-600 hover:to-red-800 transition-all"
                      style={{ fontFamily: "Roboto Mono, monospace" }}
                    >
                      CNC-Approver
                    </span>
                    <br />
                    <span
                      className="text-white cursor-pointer rounded-md px-3 text-md uppercase bg-gradient-to-r from-red-500 to-red-700 shadow-md hover:shadow-lg hover:from-red-600 hover:to-red-800 transition-all"
                      style={{ fontFamily: "Roboto Mono, monospace" }}
                    >
                      Super Admin
                    </span>
                  </div>
                </div>
              </div>
            )}

            {(role === "superadmin" ||
              assignment === "nhq_checker" ||
              assignment === "cnc_approval") && (
              <div className="flex-shrink-0 bg-gradient-to-r text-white rounded-lg shadow-lg p-4 w-full h-56 bg-red-800">
                <div className="text-center font-bold text-xl mb-2 uppercase">
                  ALT VERIFIED APPLICATION
                </div>
                <div
                  className="text-center font-semibold mt-8"
                  onClick={() => {
                    navigate("/alt-verify");
                  }}
                >
                  <span
                    className="text-red-500 bg-white cursor-pointer  rounded-2xl py-1 px-2 text-lg"
                    style={{ fontFamily: "Roboto Mono, monospace" }}
                  >
                    {" "}
                    ✔ Verified
                  </span>
                </div>
              </div>
            )}

            {(role === "superadmin" ||
              assignment === "nhq_checker" ||
              assignment === "cnc_approval") && (
              <div className="flex-shrink-0 bg-gradient-to-r text-white rounded-lg shadow-lg p-4 w-full h-56 bg-green-800">
                <div className="text-center font-bold text-xl mb-2 uppercase">
                  LT VERIFIED APPLICATION
                </div>
                <div
                  className="text-center font-semibold mt-8"
                  onClick={() => {
                    navigate("/lt-verify");
                  }}
                >
                  <span
                    className="text-red-500 bg-white cursor-pointer  rounded-2xl py-1 px-2 text-lg"
                    style={{ fontFamily: "Roboto Mono, monospace" }}
                  >
                    {" "}
                    ✔ Verified
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="bg-white rounded-lg shadow-md p-4 mt-6 h-96">
            <Bar data={data} />
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
