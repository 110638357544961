

import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../constant/constant";

const ShowHwbDashboard = () => {
  const [usersData, setUsers] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [currentViewState, setCurrentViewState] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterType, setFilterType] = useState(null);
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`${BASE_URL}/api/v1/ltuser`, axiosConfig);
      const users = response.data;
      setUsers(users);

      const states = Array.from(new Set(users.map((user) => user.STATE || "Unknown")));
      const aggregatedData = states.map((state) => {
        const stateUsers = users.filter((user) => user.STATE === state);
        const total = stateUsers.length;
        const valid = stateUsers.filter((user) => user.STATUS === "Active").length;
        const validKYT = stateUsers.filter(
          (user) =>
            user.STATUS === "Active" &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;
        const invalid = total - valid;
        return { state, total, valid, validKYT, invalid, stateUsers };
      });

      setStateData(aggregatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (state, type) => {
    const stateUsers = stateData.find((data) => data.state === state)?.stateUsers || [];
    let filtered = [];

    switch (type) {
      case "total":
        filtered = stateUsers;
        break;
      case "valid":
        filtered = stateUsers.filter((user) => user.STATUS === "Active");
        break;
      case "validKYT":
        filtered = stateUsers.filter(
          (user) =>
            user.STATUS === "Active" &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        );
        break;
      case "invalid":
        filtered = stateUsers.filter((user) => user.STATUS !== "Active");
        break;
      default:
        break;
    }

    setCurrentViewState(state);
    setFilterType(type);
    setFilteredUsers(filtered);
  };

  const handleBackToDashboard = () => {
    setCurrentViewState(null);
    setFilterType(null);
    setFilteredUsers([]);
  };

  const renderStateTable = () => (
    <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
      <thead className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white">
        <tr>
          {["State", "Active", "Valid", "Valid + KYT", "Invalid"].map((header) => (
            <th key={header} className="px-4 py-2 text-left text-sm font-semibold uppercase">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {stateData.map((data, index) => (
          <tr
            key={index}
            className="hover:bg-gray-100 transition-all duration-200 border-b last:border-none"
          >
            <td className="px-4 py-1 text-gray-700 font-medium">{data.state}</td>
            <td
              className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
              onClick={() => handleViewDetails(data.state, "total")}
            >
              {data.total}
            </td>
            <td
              className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
              onClick={() => handleViewDetails(data.state, "valid")}
            >
              {data.valid}
            </td>
            <td
              className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
              onClick={() => handleViewDetails(data.state, "validKYT")}
            >
              {data.validKYT}
            </td>
            <td
              className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
              onClick={() => handleViewDetails(data.state, "invalid")}
            >
              {data.invalid}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderFilteredUsersTable = () => (
    <div>
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
        {`User Data for State: ${currentViewState} (${filterType.toUpperCase()})`}
      </h2>
      <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gradient-to-r from-green-600 to-teal-600 text-white">
          <tr>
            {["Name", "State", "Section", "Status", "Renewed Upto"].map((header) => (
              <th key={header} className="px-4 py-3 text-left text-sm font-semibold uppercase">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user, index) => (
            <tr
              key={index}
              className="hover:bg-gray-100 transition-all duration-200 border-b last:border-none"
            >
              <td className="px-4 py-3 text-gray-700 font-medium">{user.name || "N/A"}</td>
              <td className="px-4 py-3 text-gray-700">{user.STATE || "Unknown"}</td>
              <td className="px-4 py-3 text-gray-700">{user.SECTION || "N/A"}</td>
              <td className="px-4 py-3 text-gray-700">{user.STATUS || "N/A"}</td>
              <td className="px-4 py-3 text-gray-700">{user.RENEWED_UPTO || "N/A"}</td>
              
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-6 flex justify-center">
        <button
          className="px-6 py-2 bg-gray-600 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:ring focus:ring-gray-400 transition-all"
          onClick={handleBackToDashboard}
        >
          Back to Dashboard
        </button>
      </div>
    </div>
  );

  return (
    <div className="px-4 sm:px-8 py-5 bg-gradient-to-r from-blue-50 to-purple-50 min-h-screen">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-extrabold text-indigo-700 uppercase">
          LT Statewise Dashboard
        </h1>
        <p className="text-gray-600 text-lg mt-2">An overview of statewise user statistics</p>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-[200px]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-600 border-opacity-70"></div>
        </div>
      ) : (
        <div className="bg-white rounded-xl p-8 shadow-2xl">
          {currentViewState ? renderFilteredUsersTable() : renderStateTable()}
        </div>
      )}
    </div>
  );
};

export default ShowHwbDashboard;





// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { BASE_URL } from "../../constant/constant";
// import { Bar } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const ActiveLtUser = () => {
//   const [active, setActiveValid] = useState([]);
//   const [states, setStates] = useState([]);
//   const [stateCounts, setStateCounts] = useState({});
//   const [selectedState, setSelectedState] = useState("");

//   const token = localStorage.getItem("adminToken");

//   const axiosConfig = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//   };

//   useEffect(() => {
//     getLt();
//   }, []);

//   const getLt = async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}/api/v1/ltuser`, axiosConfig);
//       console.log(response.data,"response")

//       const validData = response.data.filter(
//         (item) =>
//           item.HONOURABLE_CHARGE_NO &&
//           item.dob &&
//           item.dob !== "NA" &&
//           item.bsgUid &&
//           item.bsgUid !== "NA" &&
//           item.email &&
//           item.email !== "NA"
//       );

//       setActiveValid(validData);

//       const stateList = [...new Set(validData.map((item) => item.STATE))];
//       setStates(stateList);

//       const counts = stateList.reduce((acc, state) => {
//         acc[state] = validData.filter((item) => item.STATE === state).length;
//         return acc;
//       }, {});
//       setStateCounts(counts);

//     } catch (error) {
//       console.error("Error fetching LT data:", error);
//     }
//   };

//   const filteredData = selectedState
//     ? active.filter((item) => item.STATE === selectedState)
//     : active;

//   const totalUsers = selectedState
//     ? stateCounts[selectedState] || 0
//     : active.length;

//   const chartData = {
//     labels: Object.keys(stateCounts),
//     datasets: [
//       {
//         label: "Users per State",
//         data: Object.values(stateCounts),
//         backgroundColor: "rgb(255, 218, 0)",
//         borderColor: "rgb(29, 86, 165)",
//         borderWidth: 1,
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     plugins: {
//       title: {
//         display: true,
//         text: "Number of Users per State",
//         font: { size: 30 },
//       },
//       legend: { display: true, position: "top" },
//     },
//   };

//   const handleStateChange = (event) => {
//     setSelectedState(event.target.value);
//   };

//   return (
//     <div className="container mx-auto p-6">
//       <h1 className="text-3xl font-extrabold text-[#1D56A5] mb-6 text-center uppercase">
//         Active LT Users by State
//       </h1>

//       {/* State Dropdown */}
//       <div className="flex justify-center mb-8">
//         <label htmlFor="stateDropdown" className="font-bold text-lg mr-4 text-[#1D56A5] uppercase">
//           Select State:
//         </label>
//         <select
//           id="stateDropdown"
//           value={selectedState}
//           onChange={handleStateChange}
//           className="px-4 py-2 border border-[#1D56A5] rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-[#FFDA00] focus:border-[#1D56A5]"
//         >
//           <option value="">All States</option>
//           {states.map((state) => (
//             <option key={state} value={state}>
//               {state}
//             </option>
//           ))}
//         </select>
//       </div>

//       {/* Total Users Display */}
//       <div className="flex justify-center mb-6">
//         <div className="text-center bg-[#FFDA00] text-[#1D56A5] font-bold py-2 px-4 rounded-lg shadow-md uppercase">
//           {selectedState
//             ? `Total Users in ${selectedState}: ${totalUsers}`
//             : `Total Users: ${totalUsers}`}
//         </div>
//       </div>

//       {/* Chart Section */}
//       <div className="mb-8">
//         <div className="shadow-lg p-6 bg-white rounded-lg">
//           <Bar data={chartData} options={chartOptions} />
//         </div>
//       </div>

//       {/* Display filtered user data */}
//       <div className="bg-white p-6 rounded-lg shadow-lg">
//         <h2 className="text-2xl font-bold text-center text-[#1D56A5] mb-4 uppercase">
//           {selectedState ? `Users in ${selectedState}` : "All Users"}
//         </h2>

//         <div className="overflow-x-auto">
//           <table className="table-auto w-full text-left border-collapse">
//             <thead>
//               <tr>
//                 <th className="py-2 px-4 border-b text-lg font-medium uppercase text-[#1D56A5]">Name</th>
//                 <th className="py-2 px-4 border-b text-lg font-medium uppercase text-[#1D56A5]">State</th>
//                 <th className="py-2 px-4 border-b text-lg font-medium uppercase text-[#1D56A5]">Email</th>
//                 <th className="py-2 px-4 border-b text-lg font-medium uppercase text-[#1D56A5]">BSG UID</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredData.map((user, index) => (
//                 <tr key={index} className="border-b hover:bg-gray-100">
//                   <td className=" lowercase px-4">{user.name}</td>
//                   <td className=" lowercase px-4">{user.STATE}</td>
//                   <td className=" lowercase px-4">{user.email}</td>
//                   <td className=" lowercase px-4">{user.bsgUid}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ActiveLtUser;