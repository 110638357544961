import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Bar, Doughnut } from "react-chartjs-2";
import Modal from "react-modal";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { BASE_URL } from "../../constant/constant";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const ShowHwbDashboard = () => {
  const [usersData, setUsers] = useState([]);
  const [totalSL, setTotalSL] = useState(0);
  const [sectionScoutCount, setSectionScoutCount] = useState({
    Scout: 0,
    Cub: 0,
    Rover: 0,
  });
  const [sectionGuideCount, setSectionGuideCount] = useState({
    Guide: 0,
    Bulbul: 0,
    Ranger: 0,
    Missing :0
  });
  const [activeParchment, setActiveParchment] = useState(0);
  const [inactiveParchment, setInactiveParchment] = useState(0);
  const [activeRenewed, setActiveRenewed] = useState(0);
  const [inactiveRenewed, setInactiveRenewed] = useState(0);
  const [activekyt, setActiveKyt] = useState(0);
  const [inactivekyt, setInactiveKyt] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalTitle, setModalTitle] = useState("");
  const navigate = useNavigate();

  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true); // Start loading

      const response = await axios.get(`${BASE_URL}/api/v1/ltuser`,axiosConfig);
      const users = response.data;
      console.log(response.data, "response");

      setUsers(users);

      // Aggregate data for Scout sections
      setSectionScoutCount({
        Scout: users.filter(
          (user) => user.SECTION?.trim().toLowerCase() === "scout"
        ).length,

        Cub: users.filter(
          (user) => user.SECTION?.trim().toLowerCase() === "cub"
        ).length,

        Rover: users.filter(
          (user) => user.SECTION?.trim().toLowerCase() === "rover"
        ).length,
      });

      // Aggregate data for Guide sections
      setSectionGuideCount({
        Guide: users.filter((user) => user.SECTION?.trim().toLowerCase() === "guide").length,
        Bulbul: users.filter((user) => user.SECTION?.trim().toLowerCase() === "bulbul").length,
        Ranger: users.filter((user) => user.SECTION?.trim().toLowerCase() === "ranger").length,
        Missing: users.filter((user) => user.SECTION?.trim().toLowerCase() === "na").length,
      });

 
      const activeCount = users.filter(
        (user) => user.STATUS === "Active"
      ).length;
      const inactiveCount = users.length - activeCount; // Calculate inactive count

      setActiveParchment(activeCount);
      setInactiveParchment(inactiveCount);

      const today = new Date(); // Get today's date
      const activerenewed = users.filter(
        (user) => new Date(user.RENEWED_UPTO) > today
      ).length; // Active if RENEWED_UPTO > today
      const inactiverenewed = users.length - activerenewed; // Calculate inactive count

      console.log(`Active Renewed: ${activerenewed}`);
      console.log(`Inactive Renewed: ${inactiverenewed}`);

      setActiveRenewed(activerenewed);
      setInactiveRenewed(inactiverenewed);

      const KYT = users.filter(
        (user) =>
          user.dob &&
          user.dob !== "NA" &&
          user.bsgUid &&
          user.bsgUid !== "NA" &&
          user.email &&
          user.email !== "NA"
      ).length;
      // Active if RENEWED_UPTO > today
      const inactiveKYT = users.length - KYT;
      console.log(inactiveKYT, "inActiveCount");
      console.log(KYT, "kyt");
      setActiveKyt(KYT);
      setInactiveKyt(inactiveKYT);

      // Set the total number of users
      setTotalSL(users.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const chartData = {
    labels: ["Scout", "Cub", "Rover", "Guide", "Bulbul", "Ranger"],
    datasets: [
      {
        label: "Scout Section",
        data: [
          sectionScoutCount.Scout,
          sectionScoutCount.Cub,
          sectionScoutCount.Rover,
          0,
          0,
          0,
          0, // Parchment Issued
          0, // Parchment Not Issued
        ],
        backgroundColor: "#4CAF50",
      },
      {
        label: "Guide Section",
        data: [
          0,
          0,
          0,
          sectionGuideCount.Guide,
          sectionGuideCount.Bulbul,
          sectionGuideCount.Ranger,
          0, // Parchment Issued
          0, // Parchment Not Issued
        ],
        backgroundColor: "#FFB74D",
      },
      // {
      //   label: "Parchment Status",
      //   data: [
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     activeParchment,    // Parchment Issued
      //     inactiveParchment,  // Parchment Not Issued
      //   ],
      //   backgroundColor: "#2196F3",
      // },
      // {
      //   label: "Honourable Status",
      //   data: [
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     activeRenewed,    // Parchment Issued
      //     inactiveRenewed,  // Parchment Not Issued
      //   ],
      //   backgroundColor: "RED",
      // },
    ],
  };

  const openModal = (title, data) => {
    setModalTitle(title);
    setModalData(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData({});
  };

  const doughnutData = (data) => ({
    labels: Object.keys(data),
    datasets: [
      {
        label: `${modalTitle} Breakdown`,
        data: Object.values(data),
        backgroundColor: ["#4CAF50", "#FFB74D", "#2196F3"],
      },
    ],
  });

  const openParchmentModal = () => {
    const data = {
      "Active User": activeParchment,
      "InAactive User": inactiveParchment,
    };
    openModal("Parchment Status", data);
  };

  const openHonourableModal = () => {
    const data = {
      Valid: activeRenewed,
      Expired: inactiveRenewed,
    };
    openModal("Honourable Status", data);
  };

  const openKytModal = () => {
    const data = {
      Yes: activekyt,
      No: inactivekyt,
    };
    openModal("KYT Status", data);
  };

  return (
    <div className="px-6 py-6 bg-gradient-to-r from-blue-200 via-indigo-200 to-purple-200 min-h-screen">
      <div className="flex justify-center items-center mb-6">
        <h1 className="text-3xl font-extrabold text-gray-900 uppercase">
          LT Dashboard
        </h1>
      </div>

      {loading ? (
        <p className="text-center text-gray-500">Loading data...</p>
      ) : (
        <div className="bg-white rounded-lg p-6 shadow-xl text-gray-700">
          <div className="grid grid-cols-2 gap-4">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/ltlist");
              }}
            >
              <div className="bg-blue-100 p-4 rounded-lg shadow-md">
                <p className="font-bold text-xl text-gray-700">
                  Total LT Holder
                </p>
                <p className="text-3xl font-extrabold text-blue-600">
                  {totalSL}
                </p>
              </div>
            </div>
            <div
              className="bg-red-100 p-4 rounded-lg shadow-md"
              onClick={openHonourableModal}
            >
              <p className="font-bold text-xl text-gray-700">
                Honourable Charge
              </p>
              <p>
                Valid :{" "}
                <span className="font-bold text-green-600">
                  {activeRenewed}
                </span>
              </p>
              <p>
                Expired:{" "}
                <span className="font-bold text-red-600">
                  {inactiveRenewed}
                </span>
              </p>
            </div>

            <div
              className="bg-red-100 p-4 rounded-lg shadow-md"
              onClick={openParchmentModal}
            >
              <p className="font-bold text-xl text-gray-700">Status</p>
              <p>
                Active User:{" "}
                <span className="font-bold text-green-600">
                  {activeParchment}
                </span>
              </p>
              <p>
                InActive User:{" "}
                <span className="font-bold text-red-600">
                  {inactiveParchment}
                </span>
              </p>
            </div>

            <div
              className="bg-green-100 p-4 rounded-lg shadow-md cursor-pointer"
              onClick={() => openModal("Scout Section", sectionScoutCount)}
            >
              <p className="font-bold text-xl text-black">Scout Section</p>
              <p className="text-xl text-black font-regular mt-2">
                Total Scout Section:{" "}
                <span className="font-bold text-green-600">
                  {sectionScoutCount.Scout +
                    sectionScoutCount.Cub +
                    sectionScoutCount.Rover}
                </span>
              </p>
              <p>
                Total 'Scout':{" "}
                <span className="font-bold text-blue-600">
                  {sectionScoutCount.Scout}
                </span>
              </p>
              <p>
                Total 'Cub':{" "}
                <span className="font-bold text-blue-600">
                  {sectionScoutCount.Cub}
                </span>
              </p>
              <p>
                Total 'Rover':{" "}
                <span className="font-bold text-blue-600">
                  {sectionScoutCount.Rover}
                </span>
              </p>
            </div>

            <div
              className="bg-purple-100 p-4 rounded-lg shadow-md cursor-pointer"
              onClick={() => openModal("Guide Section", sectionGuideCount)}
            >
              <p className="font-bold text-xl text-gray-700">Guide Section</p>
              <p className="text-xl text-gray-700 mt-2">
                Total Guide Section:{" "}
                <span className="font-bold text-purple-600">
                  {sectionGuideCount.Guide +
                    sectionGuideCount.Bulbul +
                    sectionGuideCount.Ranger}
                </span>
              </p>
              <p>
                Total 'Guide':{" "}
                <span className="font-bold text-blue-600">
                  {sectionGuideCount.Guide}
                </span>
              </p>
              <p>
                Total 'Bulbul':{" "}
                <span className="font-bold text-blue-600">
                  {sectionGuideCount.Bulbul}
                </span>
              </p>
              <p>
                Total 'Ranger':{" "}
                <span className="font-bold text-blue-600">
                  {sectionGuideCount.Ranger}
                </span>
              </p>
              <p>
                Missing:{" "}
                <span className="font-bold text-blue-600">
                  {sectionGuideCount.Missing}
                </span>
              </p>
            </div>

            <div
              className="bg-purple-100 p-4 rounded-lg shadow-md cursor-pointer"
              onClick={openKytModal}
            >
              <p className="font-bold text-xl text-gray-700">
                Know Your Trainers(KYT)
              </p>

              <p>
                Yes:{" "}
                <span className="font-bold text-blue-600">{activekyt}</span>
              </p>
              <p>
                No:{" "}
                <span className="font-bold text-blue-600">{inactivekyt}</span>
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="mt-10">
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: { display: true, position: "top" },
              title: {
                display: true,
                text: "Scout & Guide Section Status Overview",
                font: {
                  size: 20,
                  weight: "bold",
                },
                color: "black",
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                max:
                  Math.max(
                    ...Object.values(sectionScoutCount),
                    ...Object.values(sectionGuideCount),
                    activeParchment,
                    inactiveParchment
                  ) + 5,
              },
            },
          }}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Section Breakdown"
        className="w-1/4 mx-auto my-32"
      >
        <h2 className="text-3xl font-bold mb-4 flex justify-center">
          {modalTitle}
        </h2>
        <Doughnut data={doughnutData(modalData)} />
        <button
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
          onClick={closeModal}
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

export default ShowHwbDashboard;
