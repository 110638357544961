import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_USER_REGISTER_ALT } from "../../constant/constant";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Circles } from "react-loader-spinner";
const AddAltUpload = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);

  const excelDateToJSDate = (serial) => {
    if (typeof serial === "number") {
      const date = new Date((serial - 25569) * 86400 * 1000);
      const day = ("0" + date.getUTCDate()).slice(-2);
      const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
      const year = date.getUTCFullYear();
      return `${day}-${month}-${year}`;
    }
    return serial; // Return as-is if it's not a number
  };

  const handleFileUpload = (data) => {
    console.log(data, "data");

    // Check if the number of entries exceeds 50
    if (data.length > 50) {
      toast.error("You can only upload a maximum of 50 entries.");
      return; // Exit the function if the limit is exceeded
    }

    data.forEach((item) => {
      // Check and convert dob, toDate, fromDate, parchmentdate if they are Excel serial numbers
      const date = item?.DATE ? excelDateToJSDate(item.DATE) : null;

      const dob = item?.dob ? excelDateToJSDate(item.dob) : null;
      const RENEWED_UPTO = item?.RENEWED_UPTO
        ? excelDateToJSDate(item.RENEWED_UPTO)
        : null;
      const ROT_DATE = item?.ROT_DATE ? excelDateToJSDate(item.ROT_DATE) : null;
      // Create the formatted object
      const formattedItem = {
        ...item,
        DATE: date || item.DATE, // Use the formatted date or the original if it's null
        dob: dob || item.dob,
        RENEWED_UPTO: RENEWED_UPTO || item.RENEWED_UPTO,
        ROT_DATE: ROT_DATE || item.ROT_DATE,
      };

      console.log(formattedItem, "formattedItem");

      setUserData((prevData) => [...prevData, formattedItem]); // Add each item to the state one by one
    });
  };

  const handleEdit = (index) => {
    const updatedData = [...userData];
    updatedData[index].editMode = true;
    setUserData(updatedData);
  };

  const handleSave = (index) => {
    const updatedData = [...userData];
    updatedData[index].editMode = false;
    setUserData(updatedData);
  };

  // const handleSubmitxl = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     for (let i = 0; i < userData.length; i += 100) {
  //       let data = userData.slice(i, Math.min(userData.length, i + 100));
  //       const token = localStorage.getItem("adminToken");
  //       const config = {
  //         url: POST_USER_REGISTER_ALT,
  //         method: "post",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         data: data,
  //       };
  //       // Iterate through the userData array and send each object one by one
  //       toast.success("Excel file submitted successfully");
  //       setUserData([]); 
  //       const response = await axios(config);
  //       console.log(response, "response");
  //     }

  
  //  // Clear user data after all have been sent
  //   } catch (error) {
  //     console.error("Error:", error);
  //     console.log(error, "error");
  //     console.log(error.response, "response");
  //     console.log(error.response.status, "responsestatus");
  //     if (error.response) {
  //       if (
  //         error.response.data &&
  //         (error.response.data.error === "User already Exist" ||
  //           error.response.status === 409)
  //       ) {
  //         // Specific error when user already exists
  //         toast.error("User already exists. Please check the entered data.");
  //       } else if (error.response.status === 409) {
  //         // General 409 error without specific message
  //         toast.error("An error occurred while submitting Excel data.");
  //       } else if (error.response.status === 401) {
  //         toast.error("UnAuthorised");
  //       } else {
  //         // Handle other types of errors
  //         toast.error("An unexpected error occurred. Please try again.");
  //       }
  //     } else {
  //       // Handle network errors or if error.response does not exist
  //       toast.error("Network error. Please check your connection.");
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleSubmitxl = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoading(true); // Indicate the loading state
    
    try {
      const token = localStorage.getItem("adminToken");
      if (!token) {
        toast.error("Unauthorized: Please login again.");
        return;
      }
  
      // Loop through `userData` in chunks of 100
      for (let i = 0; i < userData.length; i += 100) {
        const dataChunk = userData.slice(i, Math.min(userData.length, i + 100));
  
        const config = {
          url: POST_USER_REGISTER_ALT,
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: dataChunk,
        };
  
        // Send the current chunk to the backend
        const response = await axios(config);
  
        console.log("Response:", response);
  
        if (response.status === 200) {
          toast.success("Excel data chunk submitted successfully!");
        }
      }
  
      // Clear userData once all chunks are processed
      setUserData([]);
      toast.success("All Excel data submitted successfully!");
  
    } catch (error) {
      // Log and handle errors
      console.error("Error:", error);
  
      if (error.response) {
        console.log("Response:", error.response);
  
        if (
          error.response.data &&
          (error.response.data.error === "User already Exist" ||
            error.response.status === 409)
        ) {
          toast.error("User already exists. Please check the entered data.");
        } else if (error.response.status === 409) {
          toast.error("A conflict occurred while submitting the data.");
        } else if (error.response.status === 401) {
          toast.error("Unauthorized: Please check your credentials.");
        }else if (error.response.status === 400) {
          toast.error("Data Already Exist ");
        }
         else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      } else {
        toast.error("Network error. Please check your internet connection.");
      }
    } finally {
      // Reset the loading state
      setLoading(false);
    }
  };
  
  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const data = [
      [
        "SL",
        "REGION",
        "STATE",
        "name",
        "SECTION",
        // "HONOURABLE_CHARGE_NO",
        // "DATE",
        // "RENEWED_UPTO",
        // "STATUS",
        "email",
        "AADHAR_NO",
        "bsgUid",
        "MOBILE",
        "dob",
        "TRG_IN_OTHER_SECTION",
        // "ROT",
        // "ROT_DATE",
        // "ROT_PLACE",
        "EDUCATION_QUALIFICATION",
        "BSG_QUALIFICATION_WITH_SECTION",
      ],
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const workbookBinary = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });
    const blob = new Blob([s2ab(workbookBinary)], {
      type: "application/octet-stream",
    });
    saveAs(blob, "altleader.xlsx");
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  //   const handleDownload1 = () => {
  //     // Construct the file URL relative to the public folder
  //     const fileUrl = `${process.env.PUBLIC_URL}/alt.xlsx`; // Update with your file name if needed

  //     // Create an anchor element and trigger download
  //     const link = document.createElement("a");
  //     link.href = fileUrl;
  //     link.download = "example.xlsx"; // Set desired file name for download
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  // };

  return (
    <>
      <h1 className="flex justify-center font-bold text-2xl py-8 text-center text-gray-800">
        ALT HOLDER (Maximum 50 Data Entries Allowed for Upload)
      </h1>

      <div className="flex flex-row">
        <div>
          <label className="text-md font-bold text-black flex justify-start">
            Download Alt Excel File
          </label>

          <button
            className="w-auto bg-gray-700 font-medium text-white px-3 py-1.5 mt-2 rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
            onClick={handleDownload}
          >
            Download{" "}
          </button>
        </div>
        <div className="ml-8">
          <label className="text-md font-bold text-black">
            Upload ALT Excel File
          </label>
          <input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0];
              if (!file) return;
              const reader = new FileReader();
              reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                handleFileUpload(jsonData);
              };
              reader.readAsArrayBuffer(file);
            }}
            accept=".xlsx, .xls"
            className="bg-slate-200 p-1 outline-none mt-1 block w-56 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
      </div>

      {loading && (
        <div className="flex justify-center items-center ">
          <Circles
            height="60"
            width="60"
            color="#4fa94d"
            ariaLabel="circles-loading"
            visible={true}
          />
        </div>
      )}
      {userData.length > 0 && (
        <div className=" mt-4">
          <h2 className="font-bold text-2xl mt-10 uppercase">
            User Data Preview
          </h2>
          <div
            className="overflow-auto"
            style={{ maxHeight: "400px", width: "940px" }}
          >
            <table className="mt-2 text-xs">
              <thead>
                <tr className="bg-gray-200 text-sm">
                  <th className="border">SL</th>
                  <th className="border">REGION</th>
                  <th className="border">STATE</th>
                  <th className="border">NAME</th>
                  <th className="border">SECTION</th>
                  {/* <th className="border">HONOURABLE CHARGE NO.</th> */}
                  {/* <th className="border">DATE</th> */}
                  {/* <th className="border">RENEWED UPTO</th> */}
                  {/* <th className="border">STATUS</th> */}
                  <th className="border">EMAIL</th>
                  <th className="border">AADHAR NO</th>
                  <th className="border">BSG_UID</th>
                  <th className="border">MOBILE</th>
                  <th className="border">DOB</th>
                  <th className="border">TRG. IN OTHER SECTION</th>
                  {/* <th className="border">ROT</th> */}
                  {/* <th className="border">ROT DATE</th> */}
                  {/* <th className="border">ROT PLACE</th> */}
                  <th className="border">EDUCATION QUALIFICATION</th>
                  <th className="border">BSG QUALIFICATION WITH SECTION</th>
                  <th className="border">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {userData.map((user, index) => (
                  <tr key={index} className="border">
                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.SL}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].SL = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.SL
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.REGION}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].REGION = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.REGION
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.STATE}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].STATE = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.STATE
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.name}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].name = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.name
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.SECTION}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].SECTION = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.SECTION
                      )}
                    </td>

                    {/* <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.HONOURABLE_CHARGE_NO}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].HONOURABLE_CHARGE_NO =
                              e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.HONOURABLE_CHARGE_NO
                      )}
                    </td> */}

                    {/* <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="date"
                          value={user.DATE}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].DATE = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.DATE
                      )}
                    </td> */}

                    {/* <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="date"
                          value={user.RENEWED_UPTO}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].RENEWED_UPTO = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.RENEWED_UPTO
                      )}
                    </td> */}

                    {/* <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.STATUS}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].STATUS = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.STATUS
                      )}
                    </td> */}

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.email}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].email = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.email
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.AADHAR_NO}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].AADHAR_NO = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.AADHAR_NO
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.bsgUid}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].bsgUid = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.bsgUid
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.MOBILE}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].MOBILE = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.MOBILE
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="date"
                          value={user.dob}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].dob = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.dob
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.TRG_IN_OTHER_SECTION}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].TRG_IN_OTHER_SECTION =
                              e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.TRG_IN_OTHER_SECTION
                      )}
                    </td>

                    {/* <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.ROT}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].ROT = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.ROT
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="date"
                          value={user.ROT_DATE}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].ROT_DATE = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.ROT_DATE
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.ROT_PLACE}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].ROT_PLACE = e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.ROT_PLACE
                      )}
                    </td> */}

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.EDUCATION_QUALIFICATION}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].EDUCATION_QUALIFICATION =
                              e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.EDUCATION_QUALIFICATION
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <input
                          type="text"
                          value={user.BSG_QUALIFICATION_WITH_SECTION}
                          onChange={(e) => {
                            const updatedData = [...userData];
                            updatedData[index].BSG_QUALIFICATION_WITH_SECTION =
                              e.target.value;
                            setUserData(updatedData);
                          }}
                        />
                      ) : (
                        user.BSG_QUALIFICATION_WITH_SECTION
                      )}
                    </td>

                    <td className="border px-3">
                      {user.editMode ? (
                        <button
                          onClick={() => handleSave(index)}
                          className="text-blue-600"
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => handleEdit(index)}
                          className="text-blue-600"
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            className="bg-gray-700 text-white font-medium px-4 py-2 mt-4 rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
            onClick={handleSubmitxl}
            disabled={loading} // Disable button when loading
          >
            {loading ? "Submitting..." : "Submit Data"}
          </button>
        </div>
      )}
    </>
  );
};

export default AddAltUpload;
