import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_USER_EXIST_HWB } from "../../constant/constant";

const Login = () => {
  const [bsgUid, setBsgUid] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [gender, setGender] = useState("");
  const [parchmentdate, setParchmentDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [name, setName] = useState("");
  const [certificateDate, setCertificateDate] = useState("");
  const [certificateNumber, setCertificateNumber] = useState("");
  const [place, setPlace] = useState("");
  const [leader, setLeader] = useState("");
  const [userData, setUserData] = useState([]);
  const [issue, setIssue] = useState("");
  const [section, setSection] = useState("");
  const [state, setState] = useState([]);
  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedDob = dob.split("-").reverse().join("-");

    const data = {
      bsgUid,
      dob: formattedDob,
      email,
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      name,
      CERTIFICATE_NUMBER: certificateNumber,
      CERTIFICATE_DATE: certificateDate,
      LEADER: leader,
      PLACE: place,
      GENDER: gender,

      ISSUED_DATE: issue || parchmentdate,
      SECTION: section,

      STATE: selectedState,
    };
    console.log(data, "data");
    try {
      const response = await axios.post(POST_USER_EXIST_HWB, data,axiosConfig);
      console.log(response, "response");
      const token = response.data.token;
      localStorage.setItem("authToken", token);
      Cookies.set("token", token);
      toast.success("Add HWB FORM Leader successfully");
      setLeader("");
      setCertificateDate("");
      setCertificateNumber("");
      setFromDate("");
      setToDate("");
      setPlace("");
    } catch (error) {
      console.log(error, "error");
      console.log(error.response, "response");
      console.log(error.response.status, "responsestatus");
      if (error.response) {
        if (
          error.response.data &&
          (error.response.data.error === "User already Exist" ||
            error.response.status === 409)
        ) {
          // Specific error when user already exists
          toast.error("User already exists. Please check the entered data.");
        } else if (error.response.status === 404) {
          // General 409 error without specific message
          toast.error("User Not Available");
        } else if (error.response.status === 400) {
          // General 409 error without specific message
          toast.error("Please Fill All the required Data");
        } else {
          // Handle other types of errors
          toast.error("An unexpected error occurred. Please try again.");
        }
      } else {
        // Handle network errors or if error.response does not exist
        toast.error("Network error. Please check your connection.");
      }
    }
  };

  return (
    <>
      <div className="bg-blue-100 border-l-4 border-blue-500 p-2 rounded-md shadow-lg mt-5">
        <div className="flex items-start">
          <svg
            className="h-6 w-6 text-blue-500 mt-0.5 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m0-4h.01M12 4v4m-6 8h12v2H6v-2z"
            />
          </svg>
          <div className="text-blue-800 ">
            <span className="font-semibold text-red-600">Disclaimer:</span>
            This form is for HWB candidates who have completed the course; upon
            submission, the system will automatically generate the parchment
            number.
          </div>
        </div>
      </div>

      <h2 className="text-3xl font-extrabold text-center text-gray-800 my-5">
        ADD HWB FORM (EXISTING HWB LEADER )
      </h2>
      <form
        className="max-w-4xl mx-auto p-8 bg-white rounded-lg shadow-lg mt-6 border border-gray-300 transition-all duration-300 hover:shadow-xl"
        onSubmit={handleSubmit}
      >
        <ToastContainer />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="bsgUid"
            >
              BSG UID Number
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="bsgUid"
              placeholder="Enter the UID Number"
              type="text"
              value={bsgUid}
              onChange={(e) => setBsgUid(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="section"
            >
              Section
            </label>
            <select
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="section"
              value={section}
              onChange={(e) => setSection(e.target.value)}
            >
              <option value="">Select Section</option>
              <option value="Cub">Cub</option>
              <option value="Ranger">Ranger</option>
              <option value="Rover">Rover</option>
              <option value="BulBul">Bulbul</option>
              <option value="Scout">Scout</option>
              <option value="Guide">Guide</option>
            </select>
          </div>
          {/* <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="dob"
            >
              Date of Birth
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="dob"
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div> */}
        </div>

        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="email"
              placeholder="Enter the Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="name"
              placeholder="Enter the Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div> */}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="gender"
            >
              Gender
            </label>
            <select
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div> */}
          {/* <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="section"
            >
              Section
            </label>
            <select
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="section"
              value={section}
              onChange={(e) => setSection(e.target.value)}
            >
              <option value="">Select Section</option>
              <option value="Cub">Cub</option>
              <option value="Ranger">Ranger</option>
              <option value="Rover">Rover</option>
              <option value="BulBul">Bulbul</option>
              <option value="Scout">Scout</option>
              <option value="Guide">Guide</option>
            </select>
          </div> */}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="fromDate"
            >
              From Date
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="fromDate"
              type="date"
              value={fromDate}
              required
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="toDate"
            >
              To Date
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="toDate"
              type="date"
              value={toDate}
              required
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="certificateNumber"
            >
              Certificate Number
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="certificateNumber"
              placeholder="Enter the Certificate Number"
              type="text"
                 required
              value={certificateNumber}
              onChange={(e) => setCertificateNumber(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="certificateDate"
            >
              Certificate Date
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="certificateDate"
              type="date"
              value={certificateDate}
                 required
              onChange={(e) => setCertificateDate(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="place"
            >
              Place
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="place"
              placeholder="Enter the Place Name"
              type="text"
                 required
              value={place}
              onChange={(e) => setPlace(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="leader"
            >
              Leader
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="leader"
              placeholder="Enter the Leader Name"
              type="text"
                 required
              value={leader}
              onChange={(e) => setLeader(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="issue"
            >
              Issue Parchment
            </label>
            <select
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="issue"
              value={issue}
                 required
              onChange={(e) => setIssue(e.target.value)}
            >
              <option value="">Choose Parchment</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          {issue === "yes" && (
            <div className="w-full">
              <label
                className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
                htmlFor="parchmentDate"
              >
                ISSUED_DATE
              </label>
              <input
                className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
                id="parchmentDate"
                type="date"
                   required
                value={parchmentdate}
                onChange={(e) => setParchmentDate(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="flex justify-center mt-6">
          <button
            className="bg-indigo-600 text-white font-semibold px-6 py-3 rounded-lg hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700 transition duration-300 ease-in-out"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default Login;
