import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../constant/constant";
const AllhwbData = () => {
  const [hwb, sethwb] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [wing, setWing] = useState("");
  const [subwing, setSubwing] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [venue, setVenue] = useState("");
  const [leader, setLeader] = useState("");
  const [certificateNumber, setCertificateNumber] = useState("");
  const [certificateDate, setCertificateDate] = useState("");
  const [parchmentNumber, setParchmentNumber] = useState("");
  const [parchmentDate, setParchmentDate] = useState("");
  

  useEffect(() => {
    gethwbData();
  }, []);

  const gethwbData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/allhwbDetails`
      );
      console.log(response.data, "response");
      sethwb(response.data);
      
      setFilteredData(response.data);
    } catch (error) {
      toast.error("Error ");
    }
  };

  const handleSearch = () => {
    const filtered = hwb.filter((item) => {
      return (
        (wing ? item.wing.toLowerCase() === wing.toLowerCase() : true) &&
        (subwing
          ? item.subwing.toLowerCase() === subwing.toLowerCase()
          : true) &&
        (fromDate ? new Date(item.fromDate) >= new Date(fromDate) : true) &&
        (toDate ? new Date(item.toDate) <= new Date(toDate) : true) &&
        (venue
          ? item.venue.toLowerCase().includes(venue.toLowerCase())
          : true) &&
       
        (certificateNumber
          ? item.certificateNumber
              .toLowerCase()
              .includes(certificateNumber.toLowerCase())
          : true) &&
        (certificateDate
          ? new Date(item.certificateDate).toISOString().split("T")[0] ===
            certificateDate
          : true) &&
          (parchmentNumber
            ? item.parchmentNumber
                .toLowerCase()
                .includes(parchmentNumber.toLowerCase())
            : true) &&
          (parchmentDate
            ? new Date(item.parchmentDate).toISOString().split("T")[0] ===
              parchmentDate
            : true)
      );
    });
    setFilteredData(filtered);
  };
  const getSubwingOptions = () => {
    if (wing === "Guide") {
      return ["HWB-Bulbul", "HWB-Guide", "HWB-Ranger"];
    } else if (wing === "Scout") {
      return ["HWB-Cub", "HWB-Scout", "HWB-Rover"];
    } else {
      return [];
    }
  };

  return (
    <>
      <ToastContainer />
      <h3 className="font-bold text-xl pl-5 uppercase">all hwb Details</h3>
      <div className="flex flex-row gap-1 my-5">
        <select
          value={wing}
          onChange={(e) => setWing(e.target.value)}
          className=" py-1 w-16 text-xs font-semibold border border-gray-300 rounded-md"
        >
          <option value=""> Wing</option>
          <option value="Guide">Guide</option>
          <option value="Scout">Scout</option>
        </select>

        <select
          value={subwing}
          onChange={(e) => setSubwing(e.target.value)}
          className=" py-1 w-16 text-xs font-semibold border border-gray-300 rounded-md"
        >
          <option value=""> Subwing</option>
          {getSubwingOptions().map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className=" py-1 w-24 text-xs font-semibold border border-gray-300 rounded-md"
        />
        <input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          className=" py-1 w-24 text-xs font-semibold  border border-gray-300 rounded-md"
        />
        <input
          type="text"
          placeholder="Enter the Venue"
          value={venue}
          onChange={(e) => setVenue(e.target.value)}
          className=" py-1  w-24  text-xs font-semibold border border-gray-300 rounded-md"
        />
       <input
          type="text"
          placeholder="Certificate Number"
          value={certificateNumber}
          onChange={(e) => setCertificateNumber(e.target.value)}
          className=" py-1 w-24 text-xs font-semibold border border-gray-300 rounded-md"
        />
         <input
          type="date"
          value={certificateDate}
          onChange={(e) => setCertificateDate(e.target.value)}
          className=" py-1 w-24 text-xs font-semibold border border-gray-300 rounded-md"
        />
        <input
          type="text"
          placeholder="Parchment Number"
          value={parchmentNumber}
          onChange={(e) => setParchmentNumber(e.target.value)}
          className=" py-1 w-24 text-xs font-semibold border border-gray-300 rounded-md"
        />
          <input
          type="date"
          value={parchmentDate}
          onChange={(e) => setParchmentDate(e.target.value)}
          className=" py-1 w-28 text-xs font-semibold border border-gray-300 rounded-md"
        />
        
       
        <button
          onClick={handleSearch}
          className="px-6 py-1 bg-blue-500 text-white rounded-md"
        >
          Search
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-160">
          <thead className="bg-gray-50">
            <tr>
              <th className=" py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Wing
              </th>
              <th className=" py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Subwing
              </th>
              <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                From Date
              </th>
              <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                To Date
              </th>
              <th className=" py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Venue
              </th>
           

             
              <th className=" py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Certificate Date
              </th>
              <th className=" py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Certificate Number
              </th>
              <th className=" py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Parchment Number
              </th>
              <th className=" py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Parchment Date
              </th>
             
              <th className=" py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Upload Certificate
              </th>
              <th className=" py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Upload Parchment
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-160">
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td className=" whitespace-nowrap text-xs  text-gray-900">
                  {item.wing}
                </td>
                <td className=" whitespace-nowrap text-xs text-gray-900">
                  {item.subwing}
                </td>
                <td className=" whitespace-nowrap text-xs w- text-gray-900">
                  {item.fromDate}
                </td>
                <td className=" whitespace-nowrap text-xs text-gray-900">
                  {item.toDate}
                </td>
                <td className=" whitespace-nowrap text-xs text-gray-900">
                  {item.venue}
                </td>
               
                <td className=" whitespace-nowrap text-xs text-gray-900">
                  {item.certificateNumber}
                </td>
                <td className=" whitespace-nowrap text-xs text-gray-900">
                  {item.certificateDate}
                </td>
                <td className=" whitespace-nowrap text-xs text-gray-900">
                  {item.parchmentNumber}
                </td>
                <td className=" whitespace-nowrap text-xs text-gray-900">
                  {item.parchmentDate}
                </td>
              
                <td className=" text-xs">
                <iframe
                    src={item.uploadCertificate}
                    width="100%"
                    height="140px"
                    title="PDF Viewer"
                  ></iframe>
                </td>

                <td className="  text-xs">
                <iframe
                    src={item.uploadparchment}
                    width="100%"
                    height="140px"
                    title="PDF Viewer"
                  ></iframe>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AllhwbData;
