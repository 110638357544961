import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AddMarque from './AddMarque'
import { BASE_URL } from '../../constant/constant';

const AllMarque = () => {
    const [data, setData] = useState([]);
    const [update, setUpdate] = useState("");

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const storedIdString = localStorage.getItem("_id");
 const userId = JSON.parse(storedIdString);
            const response = await axios.get(`${BASE_URL}/api/v1/getmarque/${userId}`);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const updateData = async (categoryId) => {
        try {
            const dataToUpdate = {
                update: update
            };
            console.log(dataToUpdate,"datatoupdate");
console.log(categoryId,"sdjbhsd")
            const response = await axios.patch(`${BASE_URL}/api/v1/updatemarque/${categoryId}`, dataToUpdate);

            console.log(response.data, "datasss");
            alert("Marque updated successfully");
            setUpdate('')

            getData();
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    return (
        <>
          <AddMarque/>
            <div className='grid grid-cols-1 md:grid-cols-7'>
             
                <div className='col-span-1 md:col-span-4'>
                    <div className='text-center  font-bold text-white bg-[#263238] h-20 mt-10 pt-8 md:w-1/2 mx-auto md:-ml-20' style={{ width: "75vw", fontSize: "20px", fontFamily: "'Nunito Sans', sans-serif" }}>
                        Edit Marquee
                    </div>
                    {data.map((item, index) => (
                        <div key={item._id} className="border border-gray-200 p-4 mt-10 rounded-md mb-4">
                            <div className="mb-2">Marque: {item.marqueData}</div>
                            <div className="flex items-center">
                                <input
                                    type='text'
                                    value={update}
                                    onChange={(e) => setUpdate(e.target.value)}
                                    className='bg-slate-100 rounded-sm py-1 px-2 mr-2 focus:outline-none focus:ring focus:border-blue-300 flex-1'
                                    placeholder='Enter the Text'
                                />
                                <button
                                    onClick={() => updateData(item._id)}
                                    className="bg-blue-500 text-white py-1 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                                >
                                    Update Marquee
                                </button>
                            </div>
                        </div>
                    ))}

                </div>       </div>
        </>
    );
};

export default AllMarque;

